export default {
  add: 'Додати предмет до каталогу',
  editProduct: 'Редагувати предмет',
  deleteProduct: 'Видалити предмет',
  deleteWarning:
    'Ви впевнені, що хочете видалити предмет? Ця дія не може бути відмінена.',
  plural: 'Предмети',
  search: 'Пошук предметів',
  selected: 'Обрані предмети',
  singular: 'Предмет',
  category: 'Категорія предмета',
  name: 'Назва предмета'
};
