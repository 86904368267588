import { UserNanoID } from './usersTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

const basePath = '/users';

const clientsBasePath = '/clients';

const workersBasePath = '/workers';

const baseProfilePath = '/profile';

const baseProfileSettingsPath = '/settings';

const baseProfilePendingPaymentsPath = '/pending-payments';

export class UserPath {
  static index() {
    return basePath;
  }

  static show(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}`;
  }

  static clients() {
    return clientsBasePath;
  }

  static clientsFavorite() {
    return `${clientsBasePath}/filters/favorite`;
  }

  static clientsFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${clientsBasePath}/filters/${searchFilterNanoId}`;
  }

  static clientsStatusNew() {
    return `${clientsBasePath}/filters/status/new`;
  }

  static clientsStatusActive() {
    return `${clientsBasePath}/filters/status/active`;
  }

  static clientsStatusPaused() {
    return `${clientsBasePath}/filters/status/paused`;
  }

  static clientsStatusDeclined() {
    return `${clientsBasePath}/filters/status/declined`;
  }

  static clientsStatusRealDecline() {
    return `${clientsBasePath}/filters/status/real-decline`;
  }

  static clientsStatusNoPayments() {
    return `${clientsBasePath}/filters/status/no-payments`;
  }

  static profile() {
    return baseProfilePath;
  }

  static userProfile(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}`;
  }

  static profileTeamAndPosition() {
    return `${baseProfilePath}/team-and-position`;
  }

  static userProfileTeamAndPosition(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/team-and-position`;
  }

  static profileDescription() {
    return `${baseProfilePath}/description`;
  }

  static userProfileDescription(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/description`;
  }

  static profileDocuments() {
    return `${baseProfilePath}/documents`;
  }

  static userProfileDocuments(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/documents`;
  }

  static profileRequirements() {
    return `${baseProfilePath}/requirements`;
  }

  static userProfileRequirements(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/requirements`;
  }

  static profileMarketingInfo() {
    return `${baseProfilePath}/marketing-info`;
  }

  static userProfileMarketingInfo(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/marketing-info`;
  }

  static profileFiles() {
    return `${baseProfilePath}/files`;
  }

  static userProfileFiles(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/files`;
  }

  static profileAccess() {
    return `${baseProfilePath}/access`;
  }

  static userProfileAccess(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/access`;
  }

  static settings() {
    return `${baseProfilePath}${baseProfileSettingsPath}`;
  }

  static userSettings(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}`;
  }

  static settingsSystem() {
    return `${baseProfilePath}${baseProfileSettingsPath}/system`;
  }

  static userSettingsSystem(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}/system`;
  }

  static settingsNotifications() {
    return `${baseProfilePath}${baseProfileSettingsPath}/notifications`;
  }

  static settingsFinanceInfo() {
    return `${baseProfilePath}${baseProfileSettingsPath}/finance-info`;
  }

  static settingsPassword() {
    return `${baseProfilePath}${baseProfileSettingsPath}/password`;
  }

  static userSettingsNotifications(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}/notifications`;
  }

  static userSettingsFinanceInfo(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}/finance-info`;
  }

  static userSettingsPassword(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfileSettingsPath}/password`;
  }

  static payments() {
    return `${baseProfilePath}/payments`;
  }

  static paymentsAccounts() {
    return `${baseProfilePath}/payments/accounts`;
  }

  static finance() {
    return `${baseProfilePath}/finance`;
  }

  static financeDebit() {
    return `${baseProfilePath}/finance/debit`;
  }

  static financeCredit() {
    return `${baseProfilePath}/finance/credit`;
  }

  static financeProjects() {
    return `${baseProfilePath}/finance/projects`;
  }

  static financeTasks() {
    return `${baseProfilePath}/finance/tasks`;
  }

  static financeBonus() {
    return `${baseProfilePath}/finance/bonus`;
  }

  static financeOld() {
    return `${baseProfilePath}/finance/old`;
  }

  static financePrepaid() {
    return `${baseProfilePath}/finance/prepaid`;
  }

  static debt() {
    return `${baseProfilePath}/debt`;
  }

  static userFinance(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance`;
  }

  static userFinanceDebit(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/debit`;
  }

  static userFinanceCredit(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/credit`;
  }

  static userFinanceProjects(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/projects`;
  }

  static userFinanceTasks(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/tasks`;
  }

  static userFinanceBonus(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/bonus`;
  }

  static userFinanceOld(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/old`;
  }

  static userFinancePrepaid(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/finance/prepaid`;
  }

  static userDebt(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/debt`;
  }

  static userPayments(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}/payments`;
  }

  static pendingPayments() {
    return `${baseProfilePath}${baseProfilePendingPaymentsPath}`;
  }

  static userPendingPayments(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfilePendingPaymentsPath}`;
  }

  static pendingPaymentsUnderpayment() {
    return `${baseProfilePath}${baseProfilePendingPaymentsPath}/underpayment`;
  }

  static userPendingPaymentsUnderpayment(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfilePendingPaymentsPath}/underpayment`;
  }

  static pendingPaymentsDebt() {
    return `${baseProfilePath}${baseProfilePendingPaymentsPath}/debt`;
  }

  static userPendingPaymentsDebt(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfilePendingPaymentsPath}/debt`;
  }

  static pendingPaymentsBadDebt() {
    return `${baseProfilePath}${baseProfilePendingPaymentsPath}/bad-debt`;
  }

  static userPendingPaymentsBadDebt(userNanoId: UserNanoID) {
    return `${basePath}/${userNanoId}${baseProfilePendingPaymentsPath}/bad-debt`;
  }

  static userPaymentsAccounts(userNanoId: UserNanoID) {
    return `${baseProfilePath}/${userNanoId}/payments/accounts`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static workers() {
    return workersBasePath;
  }

  static workersFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${workersBasePath}/filters/${searchFilterNanoId}`;
  }

  static workersFavorite() {
    return `${workersBasePath}/filters/favorite`;
  }

  static workersBlocked() {
    return `${workersBasePath}/filters/blocked`;
  }

  static workersActive() {
    return `${workersBasePath}/filters/active`;
  }
}
