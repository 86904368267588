export default {
  addToFavorites: 'Додати в вподобані',
  any: 'Будь-який',
  and: 'та',
  artists: 'Artists',
  assignAsCreator: 'Призначити постановником',
  attach: 'Прикріпити',
  backtrace: 'Стек викликів',
  cancel: 'Скасувати',
  caution: 'Обережно!',
  clear: 'Очистити',
  clearSelection: 'Очистити вибір',
  client: 'Клієнт',
  clientDebt: 'Заборгованість клієнта',
  clients: 'Клієнти',
  close: 'Закрити',
  confirm: 'Підтвердити',
  copyLink: 'Копіювати посилання',
  create: 'Створити',
  debt: 'Борг',
  delete: 'Видалити',
  delete_filter: 'Видалити фільтр',
  description: 'Опис',
  download: 'Завантажити',
  downloadTaskResult: 'Завантажити результати задачі',
  errorMessage: 'Повідомлення про помилку',
  expand_view: 'Відкрити перегляд',
  favorite: 'Вподобане',
  filterFavorite: 'Фільтрувати вподобані',
  flagged: 'Позначене',
  generate: 'Згенерувати',
  hide: 'Сховати',
  hideList: 'Приховати список',
  homepage: 'Повернутись на головну',
  info: 'Інформація',
  invisibleForClient: 'Ховати від клієнтів',
  invisibleForWorker: 'Ховати від працівника',
  invite_user: 'Запросити користувача',
  log_out: 'Вийти',
  login_page: 'Перейти на сторінку авторизації',
  members: 'Учасники',
  membersGenitiveLowerCase: 'учасникiв',
  model: 'Модель',
  new: 'Новий',
  no: 'Ні',
  noResultsYet: 'Результати наразі відсутні',
  noSearchResults: 'Варіанти відсутні',
  of: 'з',
  open_in_new_tab: 'Відкрити в новій вкладці',
  or: 'Або',
  pinned: 'Закріплене',
  postMessage: 'Відправити повідомлення',
  processing: 'Обробка...',
  remove: 'Видалити',
  removeFromFavorites: 'Прибрати з вподобаних',
  reset_password: 'Скинути пароль',
  save: 'Зберегти',
  saveFilter: 'Зберегти фільтр',
  savedFilters: 'Збережені фільтри',
  search: 'Пошук',
  searchResults: 'Результати пошуку',
  search_user_by_name: 'Пошук по імені користувача',
  searching: 'Пошук...',
  secondsGenitive: 'секунд',
  select: 'Обрати',
  selectToAddToTheProject: 'Обрати, щоб додати до проекту',
  selected: 'Обрано',
  sendInstructions: 'Надіслати інструкції',
  sendToAllTasks: 'Надіслати в усі задачі',
  setDueDate: 'Обрати дату завершення',
  settings: 'Налаштування',
  showList: 'Показати список',
  showMore: 'Показати ще',
  showing: 'Показано',
  sign_out: 'Вийти',
  sku: 'SKU',
  submit: 'Надіслати',
  total: 'Підсумок',
  updateImageVersions: 'Оновити версії зображення',
  viewAll: 'Подивитись все',
  viewAllMembers: 'Переглянути всіх учасників',
  viewProfile: 'Переглянути профіль',
  visibleForClient: 'Показувати клієнтові',
  workers: 'Працівники',
  yes: 'Так',
  system: 'Системна',
  colorSchemeDark: 'Темна',
  colorSchemeLight: 'Світла',
  colorScheme: 'Кольорова схема',
  show_n_selected_items: {
    show: 'Показати',
    selected_items: 'обраних елементів'
  },
  timer: {
    days: 'днів',
    hours: 'годин',
    minutes: 'хвилини',
    seconds: 'секунд'
  }
};
