import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProfilesKeyType {
  access: LocalesKey;
  atLowerCase: LocalesKey;
  accounts: LocalesKey;
  averageEvaluation: LocalesKey;
  balance: LocalesKey;
  basicInfo: LocalesKey;
  debt: LocalesKey;
  description: LocalesKey;
  documents: LocalesKey;
  editProfilePicture: LocalesKey;
  files: LocalesKey;
  marketingInfo: LocalesKey;
  rate: LocalesKey;
  ratio: LocalesKey;
  records: LocalesKey;
  requirements: LocalesKey;
  singular: LocalesKey;
  teamAndPosition: LocalesKey;
  underpayments: LocalesKey;
  totalHours: LocalesKey;
  totalHoursDay: LocalesKey;
  totalPayments: LocalesKey;
  totalProjects: LocalesKey;
  totalTasks: LocalesKey;
  kAvr: LocalesKey;
  kMed: LocalesKey;
  userMessages: LocalesKey;
  userResults: LocalesKey;
}

export const profilesKeys = keyPathMirror<ProfilesKeyType, string>(
  {
    access: null,
    atLowerCase: null,
    accounts: null,
    averageEvaluation: null,
    balance: null,
    basicInfo: null,
    debt: null,
    description: null,
    documents: null,
    editProfilePicture: null,
    files: null,
    marketingInfo: null,
    rate: null,
    ratio: null,
    records: null,
    requirements: null,
    singular: null,
    underpayments: null,
    teamAndPosition: null,
    totalHours: null,
    totalHoursDay: null,
    totalPayments: null,
    totalProjects: null,
    totalTasks: null,
    kAvr: null,
    kMed: null,
    userMessages: null,
    userResults: null
  },
  'models.profiles.'
);
