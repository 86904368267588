import { FetchCurrentUserCacheKey } from '../../auth/authTypes';

import { FetchUserProfileCacheKey, UserID, UserNanoID } from './usersTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';

type UsersType = 'clients' | 'workers' | null;

export class UserCache {
  static currentUserCacheKey() {
    return 'currentUser' as FetchCurrentUserCacheKey;
  }

  static indexCacheKey() {
    return 'users' as FetchUserProfileCacheKey;
  }

  static usersFilterCacheKey(filterName: string) {
    return `users-filter-${filterName}` as FetchUserProfileCacheKey;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `users-filtered-${searchFilterNanoId}`;
  }

  static filteredWorkersCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `workers-filtered-${searchFilterNanoId}`;
  }

  static fileAttachmentsCacheKey(
    userNanoId: UserNanoID,
    type: 'image' | 'file'
  ) {
    return `user-${userNanoId}-${type}-file-attachments`;
  }

  static recentlySelectedUsersCacheKey(usersType: UsersType) {
    return `recently-selected-users-${usersType}`;
  }

  static usersSearchCacheKey(usersType: UsersType) {
    return `users-search-${usersType}`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `users-select-${fieldName}` as FetchUserProfileCacheKey;
  }

  static showCacheKey() {
    return 'user' as FetchUserProfileCacheKey;
  }

  static showMenuCacheKey() {
    return 'user-menu' as FetchUserProfileCacheKey;
  }

  static showQueryKey(userNanoId: UserNanoID) {
    return ['user', userNanoId];
  }

  static showRedirectCacheKey(userId: UserID) {
    return `user-${userId}-redirect`;
  }

  static showFinanceCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-finance`;
  }

  static showPendingPaymentsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-pending-payments`;
  }

  static transactionsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-transactions`;
  }

  static unpaidBillsCacheKey(userNanoId: UserNanoID) {
    return `user-${userNanoId}-unpaid-bills`;
  }

  static clientsCacheKey() {
    return 'clients';
  }

  static clientsFavoriteCacheKey() {
    return 'clients-favorite';
  }

  static filteredClientsCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `clients-filtered-${searchFilterNanoId}`;
  }

  static clientsFilterCacheKey() {
    return 'clientsFilter';
  }

  static selectFieldCacheKey() {
    return 'select-field-users';
  }

  static clientManagersMultiSelectFieldCacheKey() {
    return 'client-managers-multi-select-field-users';
  }

  static projectManagersMultiSelectFieldCacheKey() {
    return 'project-managers-multi-select-field-users';
  }

  static clientsMultiSelectFieldCacheKey() {
    return 'clients-multi-select-field-users';
  }

  static selectedClientsMultiSelectFieldCacheKey(fieldName: string) {
    return `clients-multi-select-field-users-${fieldName}`;
  }

  static workersCacheKey() {
    return 'workers';
  }

  static workersBlockedCacheKey() {
    return 'workers-blocked';
  }

  static workersFavoriteCacheKey() {
    return 'workers-favorite';
  }

  static workersActiveCacheKey() {
    return 'workers-active';
  }
}
