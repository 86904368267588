export const VERSION = '1.3.19';

export const APP_CRM_NAME = process.env.NEXT_PUBLIC_APP_CRM_NAME;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const GRAPHQL_API_URI = process.env.NEXT_PUBLIC_GRAPHQL_API_URI;
export const UPLOADS_API_URI = process.env.NEXT_PUBLIC_UPLOADS_API_URI;
export const MARKETING_API_URL = process.env.NEXT_PUBLIC_MARKETING_API_URL;

export const FILE_ATTACHMENTS_API_URI =
  process.env.NEXT_PUBLIC_FILE_ATTACHMENTS_API_URI;

export const FILES_API_URI =
  process.env.NEXT_PUBLIC_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_files`;
export const IMAGES_API_URI =
  process.env.NEXT_PUBLIC_IMAGES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_images`;
export const MAX_FILES_API_URI =
  process.env.NEXT_PUBLIC_MAX_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_max_files`;
export const SOURCE_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_source_files`;
export const CLIENT_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_client_files`;
export const FILE_IMPORTS_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_file_imports`;
export const TEXTURE_FILES_API_URI =
  process.env.NEXT_PUBLIC_SOURCE_FILES_API_URI ||
  `${
    process.env.NEXT_PUBLIC_UPLOADS_API_URI ||
    process.env.NEXT_PUBLIC_GRAPHQL_API_URI
  }_texture_files`;

export const NEW_PRODUCTS_API_URI = `${process.env.NEXT_PUBLIC_NEW_API_URI}/products`;

export const STATIC_API_URI = process.env.NEXT_PUBLIC_STATIC_API_URI;
export const PROXY_STATIC_TARGET = process.env.NEXT_PUBLIC_PROXY_STATIC_TARGET;

export const SHOW_DEBUG = process.env.NEXT_PUBLIC_SHOW_DEBUG;
export const USE_CONSOLE = process.env.NEXT_PUBLIC_USE_CONSOLE;

export const PRIVACY_PAGE_NANOID = process.env.NEXT_PUBLIC_PRIVACY_PAGE_NANOID;
export const PRIVACY_POLICY_PAGE_NANOID =
  process.env.NEXT_PUBLIC_PRIVACY_POLICY_PAGE_NANOID;
export const SERVICE_AGREEMENT_PAGE_NANOID =
  process.env.NEXT_PUBLIC_SERVICE_AGREEMENT_PAGE_NANOID;
export const PAYMENTS_PAGE_NANOID =
  process.env.NEXT_PUBLIC_PAYMENTS_PAGE_NANOID;
export const REFUND_PAGE_NANOID = process.env.NEXT_PUBLIC_REFUND_PAGE_NANOID;
export const HELP_PAGE_NANOID = process.env.NEXT_PUBLIC_HELP_PAGE_NANOID;

export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID;

export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const HOTJAR_SV = process.env.NEXT_PUBLIC_HOTJAR_SV;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL;
export const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
export const SUPABASE_SERVICE_KEY =
  process.env.NEXT_PUBLIC_SUPABASE_SERVICE_KEY;
