import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface UsersKeyType {
  activateUser: LocalesKey;
  activateWarning: LocalesKey;
  add: LocalesKey;
  addCellsToUsers: LocalesKey;
  addGroupsToUsers: LocalesKey;
  all: LocalesKey;
  balance: LocalesKey;
  blockedSingular: LocalesKey;
  blockedPlural: LocalesKey;
  blockUser: LocalesKey;
  blockWarning: LocalesKey;
  clientsPlural: LocalesKey;
  cm: LocalesKey;
  debt: LocalesKey;
  group: LocalesKey;
  plural: LocalesKey;
  pluralLowerCase: LocalesKey;
  workersPlural: LocalesKey;
  pendingPayments: {
    plural: LocalesKey;
    singular: LocalesKey;
    types: {
      badDebt: LocalesKey;
      debt: LocalesKey;
      underpayment: LocalesKey;
    };
    no: LocalesKey;
  };
  search: LocalesKey;
  select: LocalesKey;
  sigmaPayments: LocalesKey;
  name: LocalesKey;
  phone: LocalesKey;
  pm: LocalesKey;
  email: LocalesKey;
  financeRole: LocalesKey;
  experience: LocalesKey;
  revenue: LocalesKey;
  kAvr: LocalesKey;
  kEdp: LocalesKey;
  lastPayments: LocalesKey;
  lastActivity: LocalesKey;
  cell: LocalesKey;
  roles: LocalesKey;
  createdAt: LocalesKey;
  status: LocalesKey;
  statuses: {
    new: LocalesKey;
    active: LocalesKey;
    paused: LocalesKey;
    declined: LocalesKey;
    realDecline: LocalesKey;
    noPayments: LocalesKey;
  };
}

export const usersKeys = keyPathMirror<UsersKeyType, string>(
  {
    activateUser: null,
    activateWarning: null,
    add: null,
    addCellsToUsers: null,
    addGroupsToUsers: null,
    all: null,
    balance: null,
    blockedSingular: null,
    blockedPlural: null,
    blockUser: null,
    blockWarning: null,
    clientsPlural: null,
    cm: null,
    debt: null,
    group: null,
    plural: null,
    pluralLowerCase: null,
    workersPlural: null,
    pendingPayments: {
      plural: null,
      singular: null,
      types: {
        badDebt: null,
        debt: null,
        underpayment: null
      },
      no: null
    },
    search: null,
    select: null,
    sigmaPayments: null,
    name: null,
    phone: null,
    pm: null,
    email: null,
    financeRole: null,
    experience: null,
    revenue: null,
    kAvr: null,
    kEdp: null,
    lastPayments: null,
    lastActivity: null,
    cell: null,
    roles: null,
    createdAt: null,
    status: null,
    statuses: {
      new: null,
      active: null,
      paused: null,
      declined: null,
      realDecline: null,
      noPayments: null
    }
  },
  'models.users.'
);
