import React, {
  ForwardedRef,
  FocusEvent,
  ChangeEvent,
  memo,
  MouseEvent
} from 'react';
import cl from 'classnames';

import { FieldIcons, I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { useTranslate } from '../../../common/hooks/useTranslate';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';

interface InputFieldProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string | null;
  errorClassName?: string;
  id?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  label?: string;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  labelClassName?: string;
  leftIcon?: FieldIcons | null;
  max?: number;
  min?: number;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  onKeyPress?: (e) => void;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  showErrorIcon?: boolean;
  step?: number | 'any';
  type?: string;
  value?: string;
  prefix?: string;
  suffix?: string;
  readOnly?: boolean;
  autoComplete?: string;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      autoFocus = false,
      className,
      disabled,
      error,
      errorClassName,
      id,
      inputClassName,
      inputWrapperClassName,
      label,
      i18nLabel,
      labelClassName,
      leftIcon = null,
      max,
      min,
      name,
      placeholder,
      i18nPlaceholder,
      onBlur,
      onChange,
      onClick,
      onKeyPress,
      required,
      showError = true,
      showErrorIcon = true,
      step,
      type,
      value,
      prefix,
      suffix,
      readOnly = false,
      autoComplete
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { t } = useTranslate();

    return (
      <div className={className}>
        {i18nLabel || label ? (
          <label
            htmlFor={id || name}
            className={cl(
              labelClassName ||
                'block text-sm font-medium text-gray-700 dark:text-gray-300'
            )}
          >
            {i18nLabel ? <Translate id={i18nLabel} /> : label}
          </label>
        ) : null}

        <div className={inputWrapperClassName}>
          {leftIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">{leftIcon}</span>
            </div>
          )}
          <input
            autoFocus={autoFocus}
            className={cl(inputClassName || 'basic-input', {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:focus:ring-red-500 dark:border-red-700 dark:placeholder-red-600 dark:bg-gray-800 dark:text-red-500':
                error,
              'pl-8': prefix,
              'pr-8': suffix
            })}
            disabled={disabled}
            id={id || name}
            max={max}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onKeyPress={onKeyPress}
            placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
            ref={ref}
            required={required}
            step={step}
            type={type}
            value={value}
            readOnly={readOnly}
            autoComplete={autoComplete}
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {prefix && (
              <span className="text-gray-500 sm:text-sm">{prefix}</span>
            )}
          </div>

          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {suffix && (
              <span className="text-gray-500 sm:text-sm">{suffix}</span>
            )}

            {error && showError && showErrorIcon && (
              <Icon
                className="h-5 w-5 text-red-500"
                icon={IconsEnum.EXCLAMATION_CIRCLE}
              />
            )}
          </div>
        </div>

        {error && showError && (
          <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </div>
    );
  }
);

InputField.displayName = 'InputField';

export default memo(InputField);
