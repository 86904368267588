import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface FormsErrorsKeyType {
  add: {
    lessThanDebt: LocalesKey;
    allocatedLessThanUnallocated: LocalesKey;
  };
  amount: {
    minAmountPayoneerTwenty: LocalesKey;
    nonNegative: LocalesKey;
  };
  required: LocalesKey;
  estimateCost: {
    nonNegative: LocalesKey;
  };
  filterName: {
    required: LocalesKey;
  };
  projectName: {
    required: LocalesKey;
  };
  policy: {
    required: LocalesKey;
  };
  regPolicy: {
    required: LocalesKey;
  };
  taskName: {
    required: LocalesKey;
  };
  fullName: {
    required: LocalesKey;
  };
  name: {
    required: LocalesKey;
  };
  email: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  phone: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  city: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  password: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  positionId: {
    required: LocalesKey;
    valid: LocalesKey;
  };
  dueDate: {
    cantBeBlank: LocalesKey;
    invalid: LocalesKey;
    inFuture: LocalesKey;
  };
  hours: {
    nonNegative: LocalesKey;
    lessEight: LocalesKey;
    required: LocalesKey;
  };
  minutes: {
    nonNegative: LocalesKey;
    lessSixty: LocalesKey;
  };
  message: {
    required: LocalesKey;
  };
  feedBackMessage: {
    required: LocalesKey;
  };
}

export const formsErrors = keyPathMirror<FormsErrorsKeyType, string>(
  {
    add: {
      lessThanDebt: null,
      allocatedLessThanUnallocated: null
    },
    amount: {
      minAmountPayoneerTwenty: null,
      nonNegative: null
    },
    required: null,
    estimateCost: {
      nonNegative: null
    },
    filterName: {
      required: null
    },
    hours: {
      nonNegative: null,
      lessEight: null,
      required: null
    },
    minutes: {
      nonNegative: null,
      lessSixty: null
    },
    projectName: {
      required: null
    },
    policy: {
      required: null
    },
    regPolicy: {
      required: null
    },
    taskName: {
      required: null
    },
    fullName: {
      required: null
    },
    name: {
      required: null
    },
    email: {
      required: null,
      valid: null
    },
    phone: {
      required: null,
      valid: null
    },
    city: {
      required: null,
      valid: null
    },
    password: {
      required: null,
      valid: null
    },
    positionId: {
      required: null,
      valid: null
    },
    dueDate: {
      cantBeBlank: null,
      invalid: null,
      inFuture: null
    },
    message: {
      required: null
    },
    feedBackMessage: {
      required: null
    }
  },
  'forms.errors.'
);
