export default {
  acceptance: 'Очікує схвалення',
  account: 'Рахунок задачі',
  add: 'Додати задачу',
  add_body: 'Додати опис задачі',
  addDescription: 'Додати опис задачі',
  addTags: 'Додати теги задачі',
  description: 'Опис задачі',
  burning: 'Невідкладні задачі',
  cancel: 'Скасувати задачу',
  changeStatus: 'Змінити статус',
  count: 'Кількість задач',
  create: 'Поставити задачу',
  create_multiple: 'Поставити кілька задач',
  create_new: 'Поставити нову задачу',
  details: 'Подробиці задачі',
  download_excel: 'Завантажити таблицю з даними усіх задач',
  download_project_excel: 'Завантажити таблицю з даними усіх задач проєкту',
  download_selected_excel: 'Завантажити таблицю з даними обраних задач',
  duplicate: 'Зробити копію',
  displayOptions: 'Параметри відображення',
  edit_description: 'Змінити опис задачі',
  edit_details: 'Змінити подробиці задачі',
  enter_name: 'Вкажіть назву задачі',
  favorite: 'Вподобані задачі',
  inviteToMultipleTasks: 'Запрошення в декілька задач',
  inviteUser: 'Запросити учасника задачі',
  import: 'Імпортувати задачі',
  leave: 'Залишити задачу',
  members: 'Учасники задачі',
  multiple: 'Кілька задач',
  my: 'Мої задачі',
  name: 'Назва задачі',
  new: 'Нова задача',
  'new...': 'Що потрібно зробити?',
  no: 'Задачі відсутні',
  pause: 'Призупинити задачу',
  plural: 'Задачі',
  pluralGenitiveLowerCase: 'задач',
  // profile: 'Task profile',
  finishTasks: 'Завершити задачі',
  finishMultipleTasks: 'Завершити декілька задач',
  remove_from: 'Видалити із задачі ',
  restore: 'Поновити задачу',
  searchByTaskName: 'Пошук за назвою задачі',
  select: 'Обрати задачу...',
  select_user: 'Обраати учасника задачі',
  selected_users: 'Обраати учасників задачі',
  set_cost: 'Встановити вартість задачі',
  sendInvoice: 'Надіслати рахунок',
  setDueDate: 'Встановити дату завершення задачі',
  show_all: 'Показати всі задачі',
  show_done: 'Показати завершені задачі',
  show_hidden: 'Показати приховані задачі',
  show_visible: 'Показати видимі задачі',
  show_only_visible: 'Не показувати приховані задачі',
  show_with_canceled: 'Показувати скасовані задачі',
  show_without_canceled: 'Не показувати скасовані задачі',
  show_with_done: 'Показувати завершені задачі',
  show_without_done: 'Не показувати завершені задачі',
  show_with_hidden: 'Показувати приховані задачі',
  showHiddenTasks: 'Показати приховані задачі',
  showCanceledTasks: 'Показати скасовані задачі',
  search: 'Пошук задач',
  single: 'Одна задача',
  singular: 'Задача',
  singular_lower_case: 'задача',
  states: {
    acceptance: 'Очікує схвалення',
    canceled: 'Скасовано',
    definition: 'Постановка завдання',
    incompleteSpecification: 'Потребує уточнень',
    paused: 'Призупинено',
    performed: 'Завершено',
    qa: 'Контроль якості'
  },
  buttons: {
    accept: 'Прийняти',
    approve: 'Затвердити',
    cancel: 'Скасувати задачу',
    checkIn: 'Check in',
    pause: 'Призупинити задачу',
    pendingMoreInfo: 'Потребує уточнень',
    restore: 'Поновити задачу',
    sendToRevision: 'Допрацювати'
  },
  status: 'Статус задачі',
  // status_change: 'Task status change',
  // tags_change: 'Task tags change',
  tags: 'Теги задачі',
  tableSettings: 'Налаштування таблиці',
  taskName: 'Назва задачі',
  pluralLowerCase: 'задачі',
  task_timer: 'Таймер задачі',
  trackTimeInMultipleTasks: 'Відстежуйте час у кількох завданнях',
  team: 'Учасники задачі',
  view: 'Дивитися задачу'
  // unassigned: 'Unassigned tasks'
};
