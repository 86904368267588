export default {
  applyToAll: 'Apply to all',
  alreadyAdded: 'Already added',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Are you sure you want to delete this record? This action cannot be undone.',
  areYouSureYouWantToCancelTheTask: 'Are you sure you want to cancel the task?',
  areYouSureYouWantToClearSelected: 'Are you sure you want to clear selected?',
  areYouSureYouWantToDeletTheBillingAddress:
    'Are you sure you want to delete the billing address?',
  dropTheFilesHere: 'Drop the files here ...',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'By rating tasks, you accept task results for the client',
  changeVisibility: 'Change visibility',
  dragAndDropFilesOr: 'Drag and drop files, or:',
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
    'Please consider creating a new task with further instructions or contact us at project thread directly.',
  pleaseProvideYourAddress: 'Please, provide your address',
  pleaseRateTheTask: 'Please rate the task',
  iConfirmThatIHaveReadAndAgreeTo: 'I confirm that I have read and agree to ',
  acceptTheTaskResult: 'Accept the task result',
  weAreGladYouAreHappyWithResult:
    "We're glad you're happy with the result! Before you accept the task, we'd like to remind you that: Once you accept the task, it will close and you won't be able to leave new comments in it. If you want the result tweaked of adjusted after that, it will be a new task and charged accordingly.",
  weAreOnline: "We're online 08:00 AM to 05:00 PM GMT Monday-Friday.",
  weAreReadyToHelp:
    "At that time we're ready to help and answer any questions.",
  writeHereWarning:
    'The team may not track this task since it has been completed, are you sure you want to leave a message here?',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'The payment has been accepted for processing. Usually the payment is processed and credited, or canceled by the payment system/bank that has issued the card within 10—30 minutes. One should not make new payment attempts during this time.',
  thePaymentHasBeenCanceled: 'The payment has been canceled.',
  theTaskResultHasBeenApproved:
    'The task result has been approved by the quality control manager. Waiting for the client to rate and accept the result.',
  thisCommentWouldBeVisibleOnlyInTimeline:
    'This comment would be visible only in Timeline',
  youWillBeRedirectedToTheProjectPageIn:
    'You will be redirected to the project page in',
  youWillBeRedirectedToThePaymentsPageIn:
    'You will be redirected to the payments page in',
  startTypingToSearchForClients: 'Start typing to search for clients',
  errorLoadingImage: 'Error loading image',
  thisActionWillAffect: 'This action will affect',
  updateCost: 'Update cost',
  updateDueDate: 'Update due date'
};
