import { usersKeys } from '../../locales/keys';
import { FetchUsersSortTypes, UserClientStatusesEnum } from './usersTypes';
import { TransactionBalanceTypes } from '../transactions/transactionsTypes';

import { UserPath } from './UserPath';

export const INITIAL_USERS_FILTERS = {};
export const INITIAL_USERS_PAGE = 1;
export const INITIAL_USERS_SORT = [FetchUsersSortTypes.CREATED_AT_DESC];
export const INITIAL_USERS_LIMIT = 50;

export const clientI18nTextStatuses = {
  [UserClientStatusesEnum.ACTIVE]: usersKeys.statuses.active,
  [UserClientStatusesEnum.DECLINED]: usersKeys.statuses.declined,
  [UserClientStatusesEnum.NEW]: usersKeys.statuses.new,
  [UserClientStatusesEnum.NO_PAYMENTS]: usersKeys.statuses.noPayments,
  [UserClientStatusesEnum.PAUSE]: usersKeys.statuses.paused,
  [UserClientStatusesEnum.REAL_DECLINE]: usersKeys.statuses.realDecline
};

export const balanceTypesProfilePaths = {
  [TransactionBalanceTypes.DEBIT_ACCOUNT]: UserPath.financeDebit(),
  [TransactionBalanceTypes.CREDIT_ACCOUNT]: UserPath.financeCredit(),
  [TransactionBalanceTypes.BONUS_AMOUNT]: UserPath.financeBonus(),
  [TransactionBalanceTypes.PROJECT_ACCOUNT]: UserPath.financeProjects(),
  [TransactionBalanceTypes.TASK_ACCOUNT]: UserPath.financeTasks(),
  [TransactionBalanceTypes.PREPAID_AMOUNT]: UserPath.financePrepaid(),
  [TransactionBalanceTypes.AMOUNT]: UserPath.financeOld()
};

export const enum UserTabs {
  PROFILE = 'Profile',
  SETTINGS = 'Settings',
  PAYMENTS = 'Payments'
}

export const enum UserPendingPaymentsTabs {
  BAD_DEBT = 'bad_debt',
  DEBT = 'debt',
  UNDERPAYMENT = 'underpayment'
}

export const enum UserSectionsTabs {
  GENERAL = 'General',
  SYSTEM = 'System',
  NOTIFICATIONS = 'Notifications',
  BASIC_INFO = 'Basic info',
  TEAM_AND_POSITION = 'Team and position',
  DESCRIPTION = 'Description',
  DOCUMENTS = 'Documents',
  REQUIREMENTS = 'Requirements',
  MARKETING_INFO = 'Marketing info',
  FINANCE_INFO = 'Finance info',
  FILES = 'Files',
  ACCESS = 'Access',
  PASSWORD = 'Password'
}

export const enum UsersPermissions {
  READ_USER_SYSTEM_PROFILE_PAGE = 'read_user_system_profile_page',
  READ_USERS_INDEX_PAGE = 'read_users_index_page',
  READ_SELF_PROFILE_SHOW_PAGE = 'read_self_profile_show_page',
  READ_SELF_BALANCE_AMOUNT_SIDEBAR_FIELD = 'read_self_balance_amount_sidebar_field',
  READ_SELF_DEBT_SIDEBAR_FIELD = 'read_self_debt_sidebar_field',
  READ_SELF_RATING_SIDEBAR_FIELD = 'read_self_rating_sidebar_field',
  READ_SELF_TOTAL_AMOUNT_SIDEBAR_FIELD = 'read_self_total_amount_sidebar_field',
  READ_SELF_TURN_AROUND_TIME_MONTHLY_SIDEBAR_FIELD = 'read_self_turn_around_time_monthly_sidebar_field',
  READ_SELF_PAID_PROJECTS_COUNT_SIDEBAR_FIELD = 'read_self_paid_projects_count_sidebar_field',
  READ_SELF_RATIO_SIDEBAR_FIELD = 'read_self_ratio_sidebar_field',
  READ_SELF_WORKER_RATE_MEDIAN = 'read_self_worker_rate_median',
  READ_USER_WORKER_RATE_MEDIAN = 'read_user_worker_rate_median',
  READ_SELF_CLIENT_RATE_MEDIAN = 'read_self_client_rate_median',
  READ_USER_CLIENT_RATE_MEDIAN = 'read_user_client_rate_median',
  READ_SELF_WORKER_RATE_AVERAGE = 'read_self_worker_rate_average',
  READ_USER_WORKER_RATE_AVERAGE = 'read_user_worker_rate_average',
  READ_SELF_CLIENT_RATE_AVERAGE = 'read_self_client_rate_average',
  READ_USER_CLIENT_RATE_AVERAGE = 'read_user_client_rate_average',
  READ_SELF_TASKS_ROLE_DEBT = 'read_self_tasks_role_debt',
  READ_USER_TASKS_ROLE_DEBT = 'read_user_tasks_role_debt',
  READ_SELF_TASK_MEMBERS_COUNT_SIDEBAR_FIELD = 'read_self_task_members_count_sidebar_field',
  READ_SELF_AVERAGE_TASKS_MARK_SIDEBAR_FIELD = 'read_self_average_tasks_mark_sidebar_field',
  READ_USER_PROFILE_SHOW_PAGE = 'read_user_profile_show_page',
  READ_USER_BALANCE_AMOUNT_SIDEBAR_FIELD = 'read_user_balance_amount_sidebar_field',
  READ_USER_DEBT_SIDEBAR_FIELD = 'read_user_debt_sidebar_field',
  READ_USER_RATING_SIDEBAR_FIELD = 'read_user_rating_sidebar_field',
  READ_USER_TOTAL_AMOUNT_SIDEBAR_FIELD = 'read_user_total_amount_sidebar_field',
  READ_USER_TURN_AROUND_TIME_MONTHLY_SIDEBAR_FIELD = 'read_user_turn_around_time_monthly_sidebar_field',
  READ_USER_PAID_PROJECTS_COUNT_SIDEBAR_FIELD = 'read_user_paid_projects_count_sidebar_field',
  READ_USER_RATIO_SIDEBAR_FIELD = 'read_user_ratio_sidebar_field',
  READ_USER_TASK_MEMBERS_COUNT_SIDEBAR_FIELD = 'read_user_task_members_count_sidebar_field',
  READ_USER_AVERAGE_TASKS_MARK_SIDEBAR_FIELD = 'read_user_average_tasks_mark_sidebar_field',
  READ_SELF_MESSAGES_SIDEBAR_FIELD = 'read_self_messages_sidebar_field',
  READ_USER_MESSAGES_SIDEBAR_FIELD = 'read_user_messages_sidebar_field',
  READ_SELF_RESULTS_SIDEBAR_FIELD = 'read_self_results_sidebar_field',
  READ_USER_RESULTS_SIDEBAR_FIELD = 'read_user_results_sidebar_field',
  READ_SELF_TRACKED_TIME_TODAY_SIDEBAR_FIELD = 'read_self_tracked_time_today_sidebar_field',
  READ_USER_TRACKED_TIME_TODAY_SIDEBAR_FIELD = 'read_user_tracked_time_today_sidebar_field',
  READ_USER_EMAIL = 'read_user_email',
  READ_SELF_EMAIL = 'read_self_email',
  READ_SELF_PROFILE_REQUIREMENTS_PAGE = 'read_self_profile_requirements_page',
  READ_SELF_PROFILE_MARKETING_INFO_PAGE = 'read_self_profile_marketing_info_page',
  READ_SELF_PROFILE_TEAM_AND_POSITION_PAGE = 'read_self_profile_team_and_position_page',
  READ_SELF_PROFILE_DESCRIPTION_PAGE = 'read_self_profile_description_page',
  READ_SELF_PROFILE_DOCUMENTS_PAGE = 'read_self_profile_documents_page',
  READ_SELF_PROFILE_FINANCE_INFO_PAGE = 'read_self_profile_finance_info_page',
  READ_SELF_PROFILE_PENDING_PAYMENTS_PAGE = 'read_self_profile_pending_payments_page',
  READ_SELF_PENDING_PAYMENTS_UNDERPAYMENT_TAB = 'read_self_pending_payments_underpayment_tab',
  READ_SELF_PENDING_PAYMENTS_DEBT_TAB = 'read_self_pending_payments_debt_tab',
  READ_SELF_PENDING_PAYMENTS_BAD_DEBT_TAB = 'read_self_pending_payments_bad_debt_tab',
  READ_USER_PROFILE_REQUIREMENTS_PAGE = 'read_user_profile_requirements_page',
  READ_USER_PROFILE_MARKETING_INFO_PAGE = 'read_user_profile_marketing_info_page',
  READ_USER_PROFILE_TEAM_AND_POSITION_PAGE = 'read_user_profile_team_and_position_page',
  READ_USER_PROFILE_DESCRIPTION_PAGE = 'read_user_profile_description_page',
  READ_USER_PROFILE_DOCUMENTS_PAGE = 'read_user_profile_documents_page',
  READ_USER_PROFILE_FINANCE_INFO_PAGE = 'read_user_profile_finance_info_page',
  READ_USER_PROFILE_SETTINGS_SYSTEM_PAGE = 'read_user_profile_settings_system_page',
  READ_SELF_PROFILE_SETTINGS_SYSTEM_PAGE = 'read_self_profile_settings_system_page',
  READ_USER_PROFILE_SETTINGS_NOTIFICATIONS_PAGE = 'read_user_profile_settings_notifications_page',
  READ_SELF_PROFILE_SETTINGS_NOTIFICATIONS_PAGE = 'read_self_profile_settings_notifications_page',
  READ_SELF_PROFILE_SETTINGS_PASSWORD_PAGE = 'read_self_profile_settings_password_page',
  READ_USER_PROFILE_SETTINGS_PASSWORD_PAGE = 'read_user_profile_settings_password_page',
  CHANGE_SELF_PASSWORD_WITHOUT_CURRENT_PASSWORD = 'change_self_password_without_current_password',
  CHANGE_USER_PASSWORD_WITHOUT_CURRENT_PASSWORD = 'change_user_password_without_current_password',
  READ_SELF_PROFILE_RECORDS_BLOCK = 'read_self_profile_records_block',
  READ_USER_PROFILE_RECORDS_BLOCK = 'read_user_profile_records_block',
  READ_SELF_PROFILE_MY_TASKS_BLOCK = 'read_self_profile_my_tasks_block',
  READ_USER_PROFILE_MY_TASKS_BLOCK = 'read_user_profile_my_tasks_block',
  READ_SELF_PROFILE_FILES_BLOCK = 'read_self_profile_files_block',
  READ_USER_PROFILE_FILES_BLOCK = 'read_user_profile_files_block',
  CHANGE_PROVIDED_SELF_FULL_NAME_FIELD = 'change_provided_self_full_name_field',
  CHANGE_PROVIDED_USER_FULL_NAME_FIELD = 'change_provided_user_full_name_field',
  READ_PROVIDED_SELF_FULL_NAME_FIELD = 'read_provided_self_full_name_field',
  READ_PROVIDED_USER_FULL_NAME_FIELD = 'read_provided_user_full_name_field',
  CHANGE_PROVIDED_SELF_FULL_NAME_EN_FIELD = 'change_provided_self_full_name_en_field',
  CHANGE_PROVIDED_USER_FULL_NAME_EN_FIELD = 'change_provided_user_full_name_en_field',
  READ_PROVIDED_SELF_FULL_NAME_EN_FIELD = 'read_provided_self_full_name_en_field',
  READ_PROVIDED_USER_FULL_NAME_EN_FIELD = 'read_provided_user_full_name_en_field',
  CHANGE_PROVIDED_SELF_FULL_NAME_UK_FIELD = 'change_provided_self_full_name_uk_field',
  CHANGE_PROVIDED_USER_FULL_NAME_UK_FIELD = 'change_provided_user_full_name_uk_field',
  READ_PROVIDED_SELF_FULL_NAME_UK_FIELD = 'read_provided_self_full_name_uk_field',
  READ_PROVIDED_USER_FULL_NAME_UK_FIELD = 'read_provided_user_full_name_uk_field',
  CHANGE_SELF_FULL_NAME_FIELD = 'change_self_full_name_field',
  CHANGE_USER_FULL_NAME_FIELD = 'change_user_full_name_field',
  READ_SELF_FULL_NAME_FIELD = 'read_self_full_name_field',
  READ_USER_FULL_NAME_FIELD = 'read_user_full_name_field',
  CHANGE_SELF_NAME_FIELD = 'change_self_name_field',
  CHANGE_USER_NAME_FIELD = 'change_user_name_field',
  READ_SELF_NAME_FIELD = 'read_self_name_field',
  READ_USER_NAME_FIELD = 'read_user_name_field',
  CHANGE_SELF_NAME_EN_FIELD = 'change_self_name_en_field',
  CHANGE_USER_NAME_EN_FIELD = 'change_user_name_en_field',
  READ_SELF_NAME_EN_FIELD = 'read_self_name_en_field',
  READ_USER_NAME_EN_FIELD = 'read_user_name_en_field',
  CHANGE_SELF_NAME_UK_FIELD = 'change_self_name_uk_field',
  CHANGE_USER_NAME_UK_FIELD = 'change_user_name_uk_field',
  READ_SELF_NAME_UK_FIELD = 'read_self_name_uk_field',
  READ_USER_NAME_UK_FIELD = 'read_user_name_uk_field',
  CHANGE_SELF_SURNAME_FIELD = 'change_self_surname_field',
  CHANGE_USER_SURNAME_FIELD = 'change_user_surname_field',
  READ_SELF_SURNAME_FIELD = 'read_self_surname_field',
  READ_USER_SURNAME_FIELD = 'read_user_surname_field',
  CHANGE_SELF_SURNAME_EN_FIELD = 'change_self_surname_en_field',
  CHANGE_USER_SURNAME_EN_FIELD = 'change_user_surname_en_field',
  READ_SELF_SURNAME_EN_FIELD = 'read_self_surname_en_field',
  READ_USER_SURNAME_EN_FIELD = 'read_user_surname_en_field',
  CHANGE_SELF_SURNAME_UK_FIELD = 'change_self_surname_uk_field',
  CHANGE_USER_SURNAME_UK_FIELD = 'change_user_surname_uk_field',
  READ_SELF_SURNAME_UK_FIELD = 'read_self_surname_uk_field',
  READ_USER_SURNAME_UK_FIELD = 'read_user_surname_uk_field',
  READ_SELF_CREATED_AT_FIELD = 'read_self_created_at_field',
  READ_USER_CREATED_AT_FIELD = 'read_user_created_at_field',
  READ_SELF_UPDATED_AT_FIELD = 'read_self_updated_at_field',
  READ_USER_UPDATED_AT_FIELD = 'read_user_updated_at_field',
  CHANGE_SELF_IMAGE_FIELD = 'change_self_image_field',
  CHANGE_USER_IMAGE_FIELD = 'change_user_image_field',
  CHANGE_SELF_NDA_FIELD = 'change_self_nda_field',
  CHANGE_USER_NDA_FIELD = 'change_user_nda_field',
  READ_SELF_NDA_FIELD = 'read_self_nda_field',
  READ_USER_NDA_FIELD = 'read_user_nda_field',
  READ_SELF_DEBIT_TRANSACTIONS_TAB = 'read_self_debit_transactions_tab',
  READ_USER_DEBIT_TRANSACTIONS_TAB = 'read_user_debit_transactions_tab',
  READ_SELF_CREDIT_TRANSACTIONS_TAB = 'read_self_credit_transactions_tab',
  READ_USER_CREDIT_TRANSACTIONS_TAB = 'read_user_credit_transactions_tab',
  READ_SELF_BONUS_TRANSACTIONS_TAB = 'read_self_bonus_transactions_tab',
  READ_USER_BONUS_TRANSACTIONS_TAB = 'read_user_bonus_transactions_tab',
  READ_SELF_PREPAID_TRANSACTIONS_TAB = 'read_self_prepaid_transactions_tab',
  READ_USER_PREPAID_TRANSACTIONS_TAB = 'read_user_prepaid_transactions_tab',
  READ_SELF_AMOUNT_TRANSACTIONS_TAB = 'read_self_amount_transactions_tab',
  READ_USER_AMOUNT_TRANSACTIONS_TAB = 'read_user_amount_transactions_tab',
  READ_SELF_PROJECT_TRANSACTIONS_TAB = 'read_self_project_transactions_tab',
  READ_USER_PROJECT_TRANSACTIONS_TAB = 'read_user_project_transactions_tab',
  READ_SELF_TASK_TRANSACTIONS_TAB = 'read_self_task_transactions_tab',
  READ_USER_TASK_TRANSACTIONS_TAB = 'read_user_task_transactions_tab',
  READ_SELF_PROFILE_TRANSACTIONS_PAGE = 'read_self_profile_transactions_page',
  READ_USER_PROFILE_TRANSACTIONS_PAGE = 'read_user_profile_transactions_page',
  READ_USER_PROFILE_PENDING_PAYMENTS_PAGE = 'read_user_profile_pending_payments_page',
  READ_USER_PENDING_PAYMENTS_UNDERPAYMENT_TAB = 'read_user_pending_payments_underpayment_tab',
  READ_USER_PENDING_PAYMENTS_DEBT_TAB = 'read_user_pending_payments_debt_tab',
  READ_USER_PENDING_PAYMENTS_BAD_DEBT_TAB = 'read_user_pending_payments_bad_debt_tab',
  READ_PROFILE_UNDERPAYMENT_COLUMN = 'read_profile_underpayment_column',
  READ_USER_MENU_PENDING_PAYMENTS_LINK = 'read_user_menu_pending_payments_link',
  READ_SELF_PROFILE_FINANCE_PAGE = 'read_self_profile_finance_page',
  READ_USER_PROFILE_FINANCE_PAGE = 'read_user_profile_finance_page',
  READ_USER_MENU_PROFILE_FINANCE_LINK = 'read_user_menu_profile_finance_link',
  READ_USER_QUICK_DATA_COMPANY_FIELD = 'read_user_quick_data_company_field',
  READ_SELF_QUICK_DATA_COMPANY_FIELD = 'read_self_quick_data_company_field',
  READ_USER_BLOCKED_ICON_FIELD = 'read_user_blocked_icon_field',
  READ_SELF_BLOCKED_ICON_FIELD = 'read_self_blocked_icon_field',
  READ_SELF_PROFILE_DEBT_PAGE = 'read_self_profile_debt_page',
  READ_USER_PROFILE_DEBT_PAGE = 'read_user_profile_debt_page',
  READ_USER_MENU_PROFILE_DEBT_LINK = 'read_user_menu_profile_debt_link',
  READ_USER_MENU_USER_DEBT_FIELD = 'read_user_menu_user_debt_field',
  READ_USER_MENU_USER_INFO = 'read_user_menu_user_info',
  READ_SELF_ID = 'read_self_id',
  READ_USER_ID = 'read_user_id',
  READ_SELF_UUID = 'read_self_uuid',
  READ_USER_UUID = 'read_user_uuid',
  READ_SELF_NANO_ID = 'read_self_nano_id',
  READ_USER_NANO_ID = 'read_user_nano_id',
  CHANGE_SELF_EMAIL = 'change_self_email',
  CHANGE_USER_EMAIL = 'change_user_email',
  READ_SELF_PHONE = 'read_self_phone',
  READ_USER_PHONE = 'read_user_phone',
  CHANGE_SELF_PHONE = 'change_self_phone',
  CHANGE_USER_PHONE = 'change_user_phone',
  READ_SELF_POSITION_FIELD = 'read_self_position_field',
  READ_USER_POSITION_FIELD = 'read_user_position_field',
  CHANGE_SELF_POSITION_FIELD = 'change_self_position_field',
  CHANGE_USER_POSITION_FIELD = 'change_user_position_field',
  READ_SELF_PROFICIENCY_LEVEL_FIELD = 'read_self_proficiency_level_field',
  READ_USER_PROFICIENCY_LEVEL_FIELD = 'read_user_proficiency_level_field',
  CHANGE_SELF_PROFICIENCY_LEVEL_FIELD = 'change_self_proficiency_level_field',
  CHANGE_USER_PROFICIENCY_LEVEL_FIELD = 'change_user_proficiency_level_field',
  READ_SELF_SPECIALIZATIONS_FIELD = 'read_self_specializations_field',
  READ_USER_SPECIALIZATIONS_FIELD = 'read_user_specializations_field',
  CHANGE_SELF_SPECIALIZATIONS_FIELD = 'change_self_specializations_field',
  CHANGE_USER_SPECIALIZATIONS_FIELD = 'change_user_specializations_field',
  READ_SELF_QUALIFICATIONS_FIELD = 'read_self_qualifications_field',
  READ_USER_QUALIFICATIONS_FIELD = 'read_user_qualifications_field',
  CHANGE_SELF_QUALIFICATIONS_FIELD = 'change_self_qualifications_field',
  CHANGE_USER_QUALIFICATIONS_FIELD = 'change_user_qualifications_field',
  READ_SELF_WORK_EXPERIENCE_FIELD = 'read_self_work_experience_field',
  READ_USER_WORK_EXPERIENCE_FIELD = 'read_user_work_experience_field',
  READ_SELF_PROJECT_MANAGER_FIELD = 'read_self_project_manager_field',
  READ_USER_PROJECT_MANAGER_FIELD = 'read_user_project_manager_field',
  CHANGE_SELF_PROJECT_MANAGER_FIELD = 'change_self_project_manager_field',
  CHANGE_USER_PROJECT_MANAGER_FIELD = 'change_user_project_manager_field',
  READ_SELF_FIRST_PAYMENT_DATE_FIELD = 'read_self_first_payment_date_field',
  READ_USER_FIRST_PAYMENT_DATE_FIELD = 'read_user_first_payment_date_field',
  READ_SELF_LAST_PAYMENT_DATE_FIELD = 'read_self_last_payment_date_field',
  READ_USER_LAST_PAYMENT_DATE_FIELD = 'read_user_last_payment_date_field',
  READ_SELF_DISCOUNT_FIELD = 'read_self_discount_field',
  READ_USER_DISCOUNT_FIELD = 'read_user_discount_field',
  CHANGE_SELF_DISCOUNT_FIELD = 'change_self_discount_field',
  CHANGE_USER_DISCOUNT_FIELD = 'change_user_discount_field',
  READ_SELF_PREFERABLE_CURRENCY_FIELD = 'read_self_preferable_currency_field',
  READ_USER_PREFERABLE_CURRENCY_FIELD = 'read_user_preferable_currency_field',
  CHANGE_SELF_PREFERABLE_CURRENCY_FIELD = 'change_self_preferable_currency_field',
  CHANGE_USER_PREFERABLE_CURRENCY_FIELD = 'change_user_preferable_currency_field',
  READ_SELF_EXPECTED_PAYMENT_DATE_FIELD = 'read_self_expected_payment_date_field',
  READ_USER_EXPECTED_PAYMENT_DATE_FIELD = 'read_user_expected_payment_date_field',
  READ_SELF_EXPECTED_PAYMENT_AMOUNT_FIELD = 'read_self_expected_payment_amount_field',
  READ_USER_EXPECTED_PAYMENT_AMOUNT_FIELD = 'read_user_expected_payment_amount_field',
  READ_SELF_DEFERRED_PAYMENT_DAYS_FIELD = 'read_self_deferred_payment_days_field',
  READ_USER_DEFERRED_PAYMENT_DAYS_FIELD = 'read_user_deferred_payment_days_field',
  CHANGE_SELF_DEFERRED_PAYMENT_DAYS_FIELD = 'change_self_deferred_payment_days_field',
  CHANGE_USER_DEFERRED_PAYMENT_DAYS_FIELD = 'change_user_deferred_payment_days_field',
  READ_SELF_PAYMENT_TYPES_FIELD = 'read_self_payment_types_field',
  READ_USER_PAYMENT_TYPES_FIELD = 'read_user_payment_types_field',
  CHANGE_SELF_PAYMENT_TYPES_FIELD = 'change_self_payment_types_field',
  CHANGE_USER_PAYMENT_TYPES_FIELD = 'change_user_payment_types_field',
  READ_SELF_FINANCE_TAGS_FIELD = 'read_self_finance_tags_field',
  READ_USER_FINANCE_TAGS_FIELD = 'read_user_finance_tags_field',
  CHANGE_SELF_FINANCE_TAGS_FIELD = 'change_self_finance_tags_field',
  CHANGE_USER_FINANCE_TAGS_FIELD = 'change_user_finance_tags_field',
  READ_SELF_PAYONEER_ID_FIELD = 'read_self_payoneer_id_field',
  READ_USER_PAYONEER_ID_FIELD = 'read_user_payoneer_id_field',
  CHANGE_SELF_PAYONEER_ID_FIELD = 'change_self_payoneer_id_field',
  CHANGE_USER_PAYONEER_ID_FIELD = 'change_user_payoneer_id_field',
  READ_SELF_PAYONEER_ID_PE_FIELD = 'read_self_payoneer_id_pe_field',
  READ_USER_PAYONEER_ID_PE_FIELD = 'read_user_payoneer_id_pe_field',
  CHANGE_SELF_PAYONEER_ID_PE_FIELD = 'change_self_payoneer_id_pe_field',
  CHANGE_USER_PAYONEER_ID_PE_FIELD = 'change_user_payoneer_id_pe_field',
  READ_SELF_PAYONEER_ID_TYPE_FIELD = 'read_self_payoneer_id_type_field',
  READ_USER_PAYONEER_ID_TYPE_FIELD = 'read_user_payoneer_id_type_field',
  CHANGE_SELF_PAYONEER_ID_TYPE_FIELD = 'change_self_payoneer_id_type_field',
  CHANGE_USER_PAYONEER_ID_TYPE_FIELD = 'change_user_payoneer_id_type_field',
  READ_SELF_FINANCE_ADDRESSES_FIELDS = 'read_self_finance_addresses_fields',
  READ_USER_FINANCE_ADDRESSES_FIELDS = 'read_user_finance_addresses_fields',
  CHANGE_SELF_DEFAULT_FINANCE_ADDRESS_BUTTON = 'change_self_default_finance_address_button',
  CHANGE_USER_DEFAULT_FINANCE_ADDRESS_BUTTON = 'change_user_default_finance_address_button',
  READ_SELF_CREATE_FINANCE_ADDRESS_BUTTON = 'read_self_create_finance_address_button',
  READ_USER_CREATE_FINANCE_ADDRESS_BUTTON = 'read_user_create_finance_address_button',
  READ_SELF_UPDATE_FINANCE_ADDRESS_BUTTON = 'read_self_update_finance_address_button',
  READ_USER_UPDATE_FINANCE_ADDRESS_BUTTON = 'read_user_update_finance_address_button',
  READ_SELF_DELETE_FINANCE_ADDRESS_BUTTON = 'read_self_delete_finance_address_button',
  READ_USER_DELETE_FINANCE_ADDRESS_BUTTON = 'read_user_delete_finance_address_button',
  READ_SELF_SCHOOL_AGREEMENT_PAGES = 'read_self_school_agreement_pages',
  READ_USER_SCHOOL_AGREEMENT_PAGES = 'read_user_school_agreement_pages',
  CHANGE_SELF_SCHOOL_AGREEMENT_PAGES = 'change_self_school_agreement_pages',
  CHANGE_USER_SCHOOL_AGREEMENT_PAGES = 'change_user_school_agreement_pages',
  DELETE_SELF_SCHOOL_AGREEMENT_PAGES = 'delete_self_school_agreement_pages',
  DELETE_USER_SCHOOL_AGREEMENT_PAGES = 'delete_user_school_agreement_pages',
  DOWNLOAD_SELF_SCHOOL_AGREEMENT_PAGES = 'download_self_school_agreement_pages',
  DOWNLOAD_USER_SCHOOL_AGREEMENT_PAGES = 'download_user_school_agreement_pages',
  READ_SELF_PASSPORT_PAGES = 'read_self_passport_pages',
  READ_USER_PASSPORT_PAGES = 'read_user_passport_pages',
  CHANGE_SELF_PASSPORT_PAGES = 'change_self_passport_pages',
  CHANGE_USER_PASSPORT_PAGES = 'change_user_passport_pages',
  DELETE_SELF_PASSPORT_PAGES = 'delete_self_passport_pages',
  DELETE_USER_PASSPORT_PAGES = 'delete_user_passport_pages',
  DOWNLOAD_SELF_PASSPORT_PAGES = 'download_self_passport_pages',
  DOWNLOAD_USER_PASSPORT_PAGES = 'download_user_passport_pages',
  READ_SELF_ITN_PAGES = 'read_self_itn_pages',
  READ_USER_ITN_PAGES = 'read_user_itn_pages',
  CHANGE_SELF_ITN_PAGES = 'change_self_itn_pages',
  CHANGE_USER_ITN_PAGES = 'change_user_itn_pages',
  DELETE_SELF_ITN_PAGES = 'delete_self_itn_pages',
  DELETE_USER_ITN_PAGES = 'delete_user_itn_pages',
  DOWNLOAD_SELF_ITN_PAGES = 'download_self_itn_pages',
  DOWNLOAD_USER_ITN_PAGES = 'download_user_itn_pages',
  READ_SELF_NDA_PAGES = 'read_self_nda_pages',
  READ_USER_NDA_PAGES = 'read_user_nda_pages',
  CHANGE_SELF_NDA_PAGES = 'change_self_nda_pages',
  CHANGE_USER_NDA_PAGES = 'change_user_nda_pages',
  DELETE_SELF_NDA_PAGES = 'delete_self_nda_pages',
  DELETE_USER_NDA_PAGES = 'delete_user_nda_pages',
  DOWNLOAD_SELF_NDA_PAGES = 'download_self_nda_pages',
  DOWNLOAD_USER_NDA_PAGES = 'download_user_nda_pages',
  READ_SELF_CO_PAGES = 'read_self_co_pages',
  READ_USER_CO_PAGES = 'read_user_co_pages',
  CHANGE_SELF_CO_PAGES = 'change_self_co_pages',
  CHANGE_USER_CO_PAGES = 'change_user_co_pages',
  DELETE_SELF_CO_PAGES = 'delete_self_co_pages',
  DELETE_USER_CO_PAGES = 'delete_user_co_pages',
  DOWNLOAD_SELF_CO_PAGES = 'download_self_co_pages',
  DOWNLOAD_USER_CO_PAGES = 'download_user_co_pages',
  READ_SELF_PA_PAGES = 'read_self_pa_pages',
  READ_USER_PA_PAGES = 'read_user_pa_pages',
  CHANGE_SELF_PA_PAGES = 'change_self_pa_pages',
  CHANGE_USER_PA_PAGES = 'change_user_pa_pages',
  DELETE_SELF_PA_PAGES = 'delete_self_pa_pages',
  DELETE_USER_PA_PAGES = 'delete_user_pa_pages',
  DOWNLOAD_SELF_PA_PAGES = 'download_self_pa_pages',
  DOWNLOAD_USER_PA_PAGES = 'download_user_pa_pages',
  READ_SELF_COWORKING_PAGES = 'read_self_coworking_pages',
  READ_USER_COWORKING_PAGES = 'read_user_coworking_pages',
  CHANGE_SELF_COWORKING_PAGES = 'change_self_coworking_pages',
  CHANGE_USER_COWORKING_PAGES = 'change_user_coworking_pages',
  DELETE_SELF_COWORKING_PAGES = 'delete_self_coworking_pages',
  DELETE_USER_COWORKING_PAGES = 'delete_user_coworking_pages',
  DOWNLOAD_SELF_COWORKING_PAGES = 'download_self_coworking_pages',
  DOWNLOAD_USER_COWORKING_PAGES = 'download_user_coworking_pages',
  READ_SELF_PE_PAGES = 'read_self_pe_pages',
  READ_USER_PE_PAGES = 'read_user_pe_pages',
  CHANGE_SELF_PE_PAGES = 'change_self_pe_pages',
  CHANGE_USER_PE_PAGES = 'change_user_pe_pages',
  DELETE_SELF_PE_PAGES = 'delete_self_pe_pages',
  DELETE_USER_PE_PAGES = 'delete_user_pe_pages',
  DOWNLOAD_SELF_PE_PAGES = 'download_self_pe_pages',
  DOWNLOAD_USER_PE_PAGES = 'download_user_pe_pages',
  READ_SELF_DOCUMENTS_FORM = 'read_self_documents_form',
  READ_USER_DOCUMENTS_FORM = 'read_user_documents_form',
  CHANGE_SELF_DOCUMENTS_FORM = 'change_self_documents_form',
  CHANGE_USER_DOCUMENTS_FORM = 'change_user_documents_form',
  DELETE_SELF_DOCUMENTS_FORM = 'delete_self_documents_form',
  DELETE_USER_DOCUMENTS_FORM = 'delete_user_documents_form',
  DOWNLOAD_SELF_DOCUMENTS_FORM = 'download_self_documents_form',
  DOWNLOAD_USER_DOCUMENTS_FORM = 'download_user_documents_form',
  READ_SELF_MENTOR_FIELD = 'read_self_mentor_field',
  READ_USER_MENTOR_FIELD = 'read_user_mentor_field',
  CHANGE_SELF_MENTOR_FIELD = 'change_self_mentor_field',
  CHANGE_USER_MENTOR_FIELD = 'change_user_mentor_field',
  READ_SELF_IS_MENTOR_FIELD = 'read_self_is_mentor_field',
  READ_USER_IS_MENTOR_FIELD = 'read_user_is_mentor_field',
  CHANGE_SELF_IS_MENTOR_FIELD = 'change_self_is_mentor_field',
  CHANGE_USER_IS_MENTOR_FIELD = 'change_user_is_mentor_field',
  READ_SELF_RATE_EDUCATION_POINT_FIELD = 'read_self_rate_education_point_field',
  READ_USER_RATE_EDUCATION_POINT_FIELD = 'read_user_rate_education_point_field',
  CHANGE_SELF_RATE_EDUCATION_POINT_FIELD = 'change_self_rate_education_point_field',
  CHANGE_USER_RATE_EDUCATION_POINT_FIELD = 'change_user_rate_education_point_field',
  READ_SELF_WILDLY_IMPORTANT_GOALS_COUNT_FIELD = 'read_self_wildly_important_goals_count_field',
  READ_USER_WILDLY_IMPORTANT_GOALS_COUNT_FIELD = 'read_user_wildly_important_goals_count_field',
  CHANGE_SELF_WILDLY_IMPORTANT_GOALS_COUNT_FIELD = 'change_self_wildly_important_goals_count_field',
  CHANGE_USER_WILDLY_IMPORTANT_GOALS_COUNT_FIELD = 'change_user_wildly_important_goals_count_field',
  READ_SELF_CELLS_FIELD = 'read_self_cells_field',
  READ_USER_CELLS_FIELD = 'read_user_cells_field',
  CHANGE_SELF_CELLS_FIELD = 'change_self_cells_field',
  CHANGE_USER_CELLS_FIELD = 'change_user_cells_field',
  READ_SELF_ADDITIONAL_INFORMATION_FIELD = 'read_self_additional_information_field',
  READ_USER_ADDITIONAL_INFORMATION_FIELD = 'read_user_additional_information_field',
  CHANGE_SELF_ADDITIONAL_INFORMATION_FIELD = 'change_self_additional_information_field',
  CHANGE_USER_ADDITIONAL_INFORMATION_FIELD = 'change_user_additional_information_field',
  READ_SELF_GROUPS_FIELD = 'read_self_groups_field',
  READ_USER_GROUPS_FIELD = 'read_user_groups_field',
  CHANGE_SELF_GROUPS_FIELD = 'change_self_groups_field',
  CHANGE_USER_GROUPS_FIELD = 'change_user_groups_field',
  READ_SELF_PROFILE_PAYMENTS_DEBT_BLOCK = 'read_self_profile_payments_debt_block',
  READ_USER_PROFILE_PAYMENTS_DEBT_BLOCK = 'read_user_profile_payments_debt_block',
  READ_SELF_PROFILE_PAYMENTS_ACCOUNTS_BLOCK = 'read_self_profile_payments_accounts_block',
  READ_USER_PROFILE_PAYMENTS_ACCOUNTS_BLOCK = 'read_user_profile_payments_accounts_block',
  READ_SELF_PROFILE_PAYMENTS_PENDING_PAYMENTS_BLOCK = 'read_self_profile_payments_pending_payments_block',
  READ_USER_PROFILE_PAYMENTS_PENDING_PAYMENTS_BLOCK = 'read_user_profile_payments_pending_payments_block',
  READ_SELF_PROFILE_SETTINGS_PAGE = 'read_self_profile_settings_page',
  READ_USER_PROFILE_SETTINGS_PAGE = 'read_user_profile_settings_page',
  READ_SELF_PROFILE_PAYMENTS_PAGE = 'read_self_profile_payments_page',
  READ_USER_PROFILE_PAYMENTS_PAGE = 'read_user_profile_payments_page',
  READ_SELF_PROFILE_FINANCE_DEBIT_PAGE = 'read_self_profile_finance_debit_page',
  READ_USER_PROFILE_FINANCE_DEBIT_PAGE = 'read_user_profile_finance_debit_page',
  READ_SELF_PROFILE_FINANCE_CREDIT_PAGE = 'read_self_profile_finance_credit_page',
  READ_USER_PROFILE_FINANCE_CREDIT_PAGE = 'read_user_profile_finance_credit_page',
  READ_SELF_PROFILE_FINANCE_PROJECTS_PAGE = 'read_self_profile_finance_projects_page',
  READ_USER_PROFILE_FINANCE_PROJECTS_PAGE = 'read_user_profile_finance_projects_page',
  READ_SELF_PROFILE_FINANCE_TASKS_PAGE = 'read_self_profile_finance_tasks_page',
  READ_USER_PROFILE_FINANCE_TASKS_PAGE = 'read_user_profile_finance_tasks_page',
  READ_SELF_PROFILE_FINANCE_OLD_PAGE = 'read_self_profile_finance_old_page',
  READ_USER_PROFILE_FINANCE_OLD_PAGE = 'read_user_profile_finance_old_page',
  READ_SELF_PROFILE_FINANCE_PREPAID_PAGE = 'read_self_profile_finance_prepaid_page',
  READ_USER_PROFILE_FINANCE_PREPAID_PAGE = 'read_user_profile_finance_prepaid_page',
  READ_SELF_PROFILE_FINANCE_BONUS_PAGE = 'read_self_profile_finance_bonus_page',
  READ_USER_PROFILE_FINANCE_BONUS_PAGE = 'read_user_profile_finance_bonus_page',
  READ_USER_MENU_PROFILE_PAYMENTS_LINK = 'read_user_menu_profile_payments_link',
  READ_WORKERS_INDEX_PAGE = 'read_workers_index_page',
  READ_WORKERS_BLOCKED_PAGE = 'read_workers_blocked_page',
  READ_WORKERS_MENU_INDEX_LINK = 'read_workers_menu_index_link',
  READ_WORKERS_MENU_FAVORITE_LINK = 'read_workers_menu_favorite_link',
  READ_WORKERS_MENU_BLOCKED_LINK = 'read_workers_menu_blocked_link',
  READ_WORKERS_MENU_ACTIVE_LINK = 'read_workers_menu_active_link',
  READ_WORKERS_TABLE_SELECTED_FIELD = 'read_workers_table_selected_field',
  READ_WORKERS_TABLE_FAVORITE_FIELD = 'read_workers_table_favorite_field',
  READ_WORKERS_TABLE_EMAIL_FIELD = 'read_workers_table_email_field',
  READ_WORKERS_TABLE_FINANCE_ROLE_FIELD = 'read_workers_table_finance_role_field',
  READ_WORKERS_TABLE_USER_BALANCE_FIELD = 'read_workers_table_user_balance_field',
  READ_WORKERS_TABLE_GROUPS_FIELD = 'read_workers_table_groups_field',
  READ_WORKERS_TABLE_PHONE_FIELD = 'read_workers_table_phone_field',
  READ_WORKERS_TABLE_ROLES_FIELD = 'read_workers_table_roles_field',
  READ_WORKERS_TABLE_RATE_EDUCATION_POINT_FIELD = 'read_workers_table_rate_education_point_field',
  READ_WORKERS_TABLE_WORKER_RATE_MEDIAN_FIELD = 'read_workers_table_worker_rate_median_field',
  READ_WORKERS_TABLE_WORK_EXPERIENCE_FIELD = 'read_workers_table_work_experience_field',
  READ_WORKERS_TABLE_CREATED_AT_FIELD = 'read_workers_table_created_at_field',
  READ_WORKERS_SEARCH_FILTERS_POPOVER = 'read_workers_search_filters_popover',
  READ_WORKERS_ADD_CELLS_BUTTON = 'read_workers_add_cells_button',
  READ_WORKERS_BLOCK_BUTTON = 'read_workers_block_button',
  READ_WORKERS_UNBLOCK_BUTTON = 'read_workers_unblock_button',
  READ_WORKERS_CELL_IDS_FILTER = 'read_workers_cell_ids_filter',
  READ_WORKERS_ROLE_IDS_FILTER = 'read_workers_role_ids_filter',
  READ_WORKERS_REGISTRATION_DATE_FILTER_BLOCK = 'read_workers_registration_date_filter_block',
  READ_WORKERS_SAVED_FILTERS_MENU = 'read_workers_saved_filters_menu',
  READ_CLIENTS_INDEX_PAGE = 'read_clients_index_page',
  READ_CLIENTS_MENU_INDEX_LINK = 'read_clients_menu_index_link',
  READ_CLIENTS_MENU_FAVORITE_LINK = 'read_clients_menu_favorite_link',
  READ_CLIENTS_SAVED_FILTERS_MENU = 'read_clients_saved_filters_menu',
  READ_CLIENTS_MENU_STATUS_NEW_LINK = 'read_clients_menu_status_new_link',
  READ_CLIENTS_MENU_STATUS_ACTIVE_LINK = 'read_clients_menu_status_active_link',
  READ_CLIENTS_MENU_STATUS_PAUSED_LINK = 'read_clients_menu_status_paused_link',
  READ_CLIENTS_MENU_STATUS_DECLINED_LINK = 'read_clients_menu_status_declined_link',
  READ_CLIENTS_MENU_STATUS_REAL_DECLINE_LINK = 'read_clients_menu_status_real_decline_link',
  READ_CLIENTS_MENU_STATUS_NO_PAYMENTS_LINK = 'read_clients_menu_status_no_payments_link',
  READ_CLIENTS_TABLE_SELECTED_FIELD = 'read_clients_table_selected_field',
  READ_CLIENTS_TABLE_FAVORITE_FIELD = 'read_clients_table_favorite_field',
  READ_CLIENTS_TABLE_EMAIL_FIELD = 'read_clients_table_email_field',
  READ_CLIENTS_TABLE_CLIENT_BALANCE_FIELD = 'read_clients_table_client_balance_field',
  READ_CLIENTS_TABLE_CLIENT_DEBT_FIELD = 'read_clients_table_client_debt_field',
  READ_CLIENTS_TABLE_CLIENT_SIGMA_PAYMENTS_FIELD = 'read_clients_table_client_sigma_payments_field',
  READ_CLIENTS_TABLE_LAST_PAYMENT_DATE_FIELD = 'read_clients_table_last_payment_date_field',
  READ_CLIENTS_TABLE_LAST_ACTIVITY_DATE_FIELD = 'read_clients_table_last_activity_date_field',
  READ_CLIENTS_TABLE_CLIENT_MANAGER_FIELD = 'read_clients_table_client_manager_field',
  READ_CLIENTS_TABLE_PROJECT_MANAGERS_FOLLOWERS_FIELD = 'read_clients_table_project_managers_followers_field',
  READ_CLIENTS_TABLE_STATUS_FIELD = 'read_clients_table_status_field',
  READ_CLIENTS_SEARCH_FILTERS_POPOVER = 'read_clients_search_filters_popover',
  READ_CLIENTS_CREATED_AT_FILTER = 'read_clients_created_at_filter',
  READ_CLIENTS_FOLLOWER_IDS_FILTER = 'read_clients_follower_ids_filter',
  READ_CLIENTS_UPSALER_ID_FILTER = 'read_clients_upsaler_id_filter',
  READ_CLIENTS_GROUP_IDS_FILTER = 'read_clients_group_ids_filter',
  READ_CLIENTS_BLOCK_BUTTON = 'read_clients_block_button',
  READ_CLIENTS_UNBLOCK_BUTTON = 'read_clients_unblock_button',
  READ_CLIENTS_ADD_GROUPS_BUTTON = 'read_clients_add_groups_button',
  READ_SELF_ADDRESS_FIELD = 'read_self_address_field',
  READ_USER_ADDRESS_FIELD = 'read_user_address_field',
  READ_SELF_FACEBOOK_FIELD = 'read_self_facebook_field',
  READ_USER_FACEBOOK_FIELD = 'read_user_facebook_field',
  READ_SELF_LINKED_IN_FIELD = 'read_self_linked_in_field',
  READ_USER_LINKED_IN_FIELD = 'read_user_linked_in_field',
  READ_SELF_SOCIAL_NETWORKS_FIELD = 'read_self_social_networks_field',
  READ_USER_SOCIAL_NETWORKS_FIELD = 'read_user_social_networks_field',
  READ_SELF_MANUAL_CLIENT_STATUS_FIELD = 'read_self_manual_client_status_field',
  READ_USER_MANUAL_CLIENT_STATUS_FIELD = 'read_user_manual_client_status_field',
  CHANGE_SELF_MANUAL_CLIENT_STATUS_FIELD = 'change_self_manual_client_status_field',
  CHANGE_USER_MANUAL_CLIENT_STATUS_FIELD = 'change_user_manual_client_status_fie',
  READ_SELF_COMPANY_FIELD = 'read_self_company_field',
  READ_USER_COMPANY_FIELD = 'read_user_company_field',
  CHANGE_SELF_COMPANY_FIELD = 'change_self_company_field',
  CHANGE_USER_COMPANY_FIELD = 'change_user_company_field',
  READ_SELF_SITE_FIELD = 'read_self_site_field',
  READ_USER_SITE_FIELD = 'read_user_site_field',
  CHANGE_SELF_ADDRESS_FIELD = 'change_self_address_field',
  CHANGE_USER_ADDRESS_FIELD = 'change_user_address_field',
  CHANGE_SELF_FACEBOOK_FIELD = 'change_self_facebook_field',
  CHANGE_USER_FACEBOOK_FIELD = 'change_user_facebook_field',
  CHANGE_SELF_LINKED_IN_FIELD = 'change_self_linked_in_field',
  CHANGE_USER_LINKED_IN_FIELD = 'change_user_linked_in_field',
  CHANGE_SELF_SOCIAL_NETWORKS_FIELD = 'change_self_social_networks_field',
  CHANGE_USER_SOCIAL_NETWORKS_FIELD = 'change_user_social_networks_field',
  CHANGE_SELF_SITE_FIELD = 'change_self_site_field',
  CHANGE_USER_SITE_FIELD = 'change_user_site_field',
  READ_SELF_CATEGORY_FIELD = 'read_self_category_field',
  READ_USER_CATEGORY_FIELD = 'read_user_category_field',
  CHANGE_SELF_CATEGORY_FIELD = 'change_self_category_field',
  CHANGE_USER_CATEGORY_FIELD = 'change_user_category_field',
  READ_SELF_COMPANY_SIZE_FIELD = 'read_self_company_size_field',
  READ_USER_COMPANY_SIZE_FIELD = 'read_user_company_size_field',
  CHANGE_SELF_COMPANY_SIZE_FIELD = 'change_self_company_size_field',
  CHANGE_USER_COMPANY_SIZE_FIELD = 'change_user_company_size_field',
  READ_SELF_SUB_CATEGORIES_FIELD = 'read_self_sub_categories_field',
  READ_USER_SUB_CATEGORIES_FIELD = 'read_user_sub_categories_field',
  CHANGE_SELF_SUB_CATEGORIES_FIELD = 'change_self_sub_categories_field',
  CHANGE_USER_SUB_CATEGORIES_FIELD = 'change_user_sub_categories_field',
  READ_SELF_INTEGRATION_FIELD = 'read_self_integration_field',
  READ_USER_INTEGRATION_FIELD = 'read_user_integration_field',
  CHANGE_SELF_INTEGRATION_FIELD = 'change_self_integration_field',
  CHANGE_USER_INTEGRATION_FIELD = 'change_user_integration_field',
  READ_SELF_NO_GO_FIELD = 'read_self_no_go_field',
  READ_USER_NO_GO_FIELD = 'read_user_no_go_field',
  CHANGE_SELF_NO_GO_FIELD = 'change_self_no_go_field',
  CHANGE_USER_NO_GO_FIELD = 'change_user_no_go_field',
  READ_SELF_BUSINESS_EMAIL_FIELD = 'read_self_business_email_field',
  READ_USER_BUSINESS_EMAIL_FIELD = 'read_user_business_email_field',
  CHANGE_SELF_BUSINESS_EMAIL_FIELD = 'change_self_business_email_field',
  CHANGE_USER_BUSINESS_EMAIL_FIELD = 'change_user_business_email_field',
  READ_SELF_ADDITIONAL_EMAIL_1_FIELD = 'read_self_additional_email_1_field',
  READ_USER_ADDITIONAL_EMAIL_1_FIELD = 'read_user_additional_email_1_field',
  CHANGE_SELF_ADDITIONAL_EMAIL_1_FIELD = 'change_self_additional_email_1_field',
  CHANGE_USER_ADDITIONAL_EMAIL_1_FIELD = 'change_user_additional_email_1_field',
  READ_SELF_ADDITIONAL_EMAIL_2_FIELD = 'read_self_additional_email_2_field',
  READ_USER_ADDITIONAL_EMAIL_2_FIELD = 'read_user_additional_email_2_field',
  CHANGE_SELF_ADDITIONAL_EMAIL_2_FIELD = 'change_self_additional_email_2_field',
  CHANGE_USER_ADDITIONAL_EMAIL_2_FIELD = 'change_user_additional_email_2_field',
  READ_SELF_SOURCE_FIELD = 'read_self_source_field',
  READ_USER_SOURCE_FIELD = 'read_user_source_field',
  CHANGE_SELF_SOURCE_FIELD = 'change_self_source_field',
  CHANGE_USER_SOURCE_FIELD = 'change_user_source_field',
  READ_SELF_MARKETING_SOURCE_FIELD = 'read_self_marketing_source_field',
  READ_USER_MARKETING_SOURCE_FIELD = 'read_user_marketing_source_field',
  CHANGE_SELF_MARKETING_SOURCE_FIELD = 'change_self_marketing_source_field',
  CHANGE_USER_MARKETING_SOURCE_FIELD = 'change_user_marketing_source_field',
  READ_SELF_COLD_SOURCE_FIELD = 'read_self_cold_source_field',
  READ_USER_COLD_SOURCE_FIELD = 'read_user_cold_source_field',
  CHANGE_SELF_COLD_SOURCE_FIELD = 'change_self_cold_source_field',
  CHANGE_USER_COLD_SOURCE_FIELD = 'change_user_cold_source_field',
  READ_SELF_REFERER_FIELD = 'read_self_referer_field',
  READ_USER_REFERER_FIELD = 'read_user_referer_field',
  CHANGE_SELF_REFERER_FIELD = 'change_self_referer_field',
  CHANGE_USER_REFERER_FIELD = 'change_user_referer_field',
  READ_SELF_REAL_DECLINE_REASONS_FIELD = 'read_self_real_decline_reasons_field',
  READ_USER_REAL_DECLINE_REASONS_FIELD = 'read_user_real_decline_reasons_field',
  CHANGE_SELF_REAL_DECLINE_REASONS_FIELD = 'change_self_real_decline_reasons_field',
  CHANGE_USER_REAL_DECLINE_REASONS_FIELD = 'change_user_real_decline_reasons_field',
  READ_SELF_URL_FIELD = 'read_self_url_field',
  READ_USER_URL_FIELD = 'read_user_url_field',
  CHANGE_SELF_URL_FIELD = 'change_self_url_field',
  CHANGE_USER_URL_FIELD = 'change_user_url_field'
}
