import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface FilesKeyType {
  plural: LocalesKey;
  addFile: LocalesKey;
  attach: LocalesKey;
  attachFromProject: LocalesKey;
  failedToUpload: LocalesKey;
  fileUploaded: LocalesKey;
  png: LocalesKey;
  jpg: LocalesKey;
  tiff: LocalesKey;
  selectFileFormat: LocalesKey;
  selectFiles: LocalesKey;
  startFilesUploading: LocalesKey;
}

export const filesKeys = keyPathMirror<FilesKeyType, string>(
  {
    plural: null,
    addFile: null,
    attach: null,
    attachFromProject: null,
    failedToUpload: null,
    fileUploaded: null,
    png: null,
    jpg: null,
    tiff: null,
    selectFileFormat: null,
    selectFiles: null,
    startFilesUploading: null
  },
  'models.files.'
);
