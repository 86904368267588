export default {
  addLifestyle: 'Add lifestyle',
  add: 'Add lifestyle to library',
  deleteLifestyle: 'Delete lifestyle',
  deleteWarning:
    'Are you sure you want to delete lifestyle? This action cannot be undone.',
  plural: 'Lifestyles',
  singular: 'Lifestyle',
  attach: 'Attach lifestyles',
  attachSelected: 'Attach selected lifestyles',
  category: 'Lifestyle category',
  editLifestyle: 'Edit lifestyle',
  name: 'Lifestyle name',
  my: 'My Lifestyles',
  search: 'Search lifestyles',
  selected: 'Selected lifestyles',
  viewAll: 'View all lifestyles',
  categories: {
    bedroom: 'Bedroom',
    commercialAreas: 'Commercial areas',
    exteriors: 'Exteriors',
    dining: 'Dining',
    livingApartments: 'Living apartments'
  }
};
