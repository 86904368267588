export default {
  activateUser: 'Активировать пользователя',
  activateWarning:
    'Вы уверены, что хотите активировать выбранных пользователей?',
  add: 'Добавить пользователя',
  addCellsToUsers: 'Добавить cells к пользователям',
  addGroupsToUsers: 'Добавить группы к пользователям',
  all: 'Все люди',
  block: 'Блокировать пользователя',
  blockedSingular: 'Заблокированный пользователь',
  blockedPlural: 'Заблокированные пользователи',
  blockUser: 'Заблокировать пользователя',
  blockWarning: 'Вы уверены, что хотите заблокировать выбранных пользователей?',
  create: 'Создание пользователя',
  deleteAccount: 'Удалить аккаунт',
  delete: 'Удаление пользователя',
  edit: 'Редактирование пользователя',
  invite: 'Пригласить пользователя',
  plural: 'Пользователи',
  pluralLowerCase: 'пользователи',
  workersPlural: 'Работники',
  search: 'Поиск пользователей',
  select: 'Выбрать пользователя',
  selected: 'Выбранные пользователи',
  singular: 'Пользователь',
  update: 'Обновление пользователя',
  createdSuccess: 'Пользователь успешно добавлен.',
  deletedSuccess: 'Пользователь успешно удален.',
  info: 'Информация о пользователе',
  count: 'Пользователи {count}',
  group: 'Группа',
  updatedSuccess: 'Пользователь успешно обновлен.',
  name: 'Имя',
  phone: 'Телефон',
  financeRole: 'Финансовая роль',
  experience: 'Опыт работы',
  revenue: 'Доход',
  kAvr: 'K AVR',
  kEdp: 'K EDP',
  cell: 'Cell',
  roles: 'Роли',
  createdAt: 'Дата создания',
  pendingPayments: {
    plural: 'Ожидаемые оплаты',
    singular: 'Ожидаемая оплата',
    types: {
      badDebt: 'Невозвратный долг',
      debt: 'Долг',
      underpayment: 'Недоплата'
    },
    no: 'Нет ожидаемых оплат'
  }
};
