export default {
  action: {
    required: 'Please enter an action'
  },
  add: {
    lessThanDebt: 'Value must be less than debt.',
    allocatedLessThanUnallocated:
      'Total allocated amount must be less than project unallocated sum.'
  },
  amount: {
    minAmountPayoneerTwenty: 'For Payoneer amount must be more than 20',
    nonNegative: 'Amount must be non-negative number'
  },
  dueDate: {
    inFuture: 'Due date must be in the future',
    invalid: 'Due date is invalid or not set'
  },
  email: {
    required: 'Please enter an email',
    valid: 'Please enter valid email'
  },
  estimateCost: {
    nonNegative: 'Estimate cost must be non-negative number'
  },
  filterName: {
    required: 'Please enter filter name'
  },
  fullName: {
    required: 'Please enter full name'
  },
  name: {
    required: 'Please enter a name'
  },
  positionId: {
    required: 'Please select role'
  },
  city: {
    required: 'Please select city'
  },
  phone: {
    required: 'Please enter a phone',
    valid: 'Please enter valid phone'
  },
  projectName: {
    required: 'Please enter project name'
  },
  password: {
    required: 'Please enter password',
    valid:
      'Please enter valid password. It should contain at least 6 characters'
  },
  policy: {
    required: 'You must accept the Payment, Refund, Privacy policy'
  },
  regPolicy: {
    required: 'You must accept the Privacy policy and Service agreement'
  },
  required: 'This field is required.',
  taskName: {
    required: 'Please enter task name'
  },
  hours: {
    nonNegative: 'Hours must be non-negative number',
    lessEight: 'Hours must be less than eight',
    required: 'Please enter hours or minutes'
  },
  minutes: {
    nonNegative: 'Minutes must be non-negative number',
    lessSixty: 'Minutes must be less than sixty'
  },
  message: {
    required: 'Please enter text or add attachments'
  },
  feedBackMessage: {
    required: 'Please enter your message'
  }
};
