import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface ProductsKeyType {
  attachSelected: LocalesKey;
  my: LocalesKey;
  plural: LocalesKey;
  search: LocalesKey;
  addProduct: LocalesKey;
  attach: LocalesKey;
  deleteProduct: LocalesKey;
  deleteWarning: LocalesKey;
  category: LocalesKey;
  editProduct: LocalesKey;
  name: LocalesKey;
  selected: LocalesKey;
  viewAll: LocalesKey;
  categories: {
    exterior: LocalesKey;
    homeDecor: LocalesKey;
    homeTextiles: LocalesKey;
    kitchen: LocalesKey;
    plants: LocalesKey;
  };
}

export const productsKeys = keyPathMirror<ProductsKeyType, string>(
  {
    attachSelected: null,
    my: null,
    plural: null,
    search: null,
    addProduct: null,
    attach: null,
    deleteProduct: null,
    deleteWarning: null,
    category: null,
    editProduct: null,
    name: null,
    selected: null,
    viewAll: null,
    categories: {
      exterior: null,
      homeDecor: null,
      homeTextiles: null,
      kitchen: null,
      plants: null
    }
  },
  'models.products.'
);
