export default {
  areYouSureYouWantToCancelTheTask: 'Вы уверены, что хотите отменить задачу?',
  areYouSureYouWantToClearSelected:
    'Вы уверены, что хотите очистить выбранное?',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'Оценивая задачи, вы принимаете результаты задачи для клиента',
  pleaseProvideYourAddress: 'Пожалуйста, предоставьте ваш адрес',
  pleaseRateTheTask: 'Оцените задачу',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'Платеж принят в обработку. Обычно платеж обрабатывается и зачисляется, либо отменяется платежной системой/банком выпустившим карту в течении 10 - 30 минут. В это время не нужно предпринимать новые попытки оплаты.',
  thePaymentHasBeenCanceled: 'Платеж отменен.',
  youWillBeRedirectedToTheProjectPageIn:
    'Вы будете перенаправлены на страницу проекта в течении',
  youWillBeRedirectedToThePaymentsPageIn:
    'Вы будете перенаправлены на страницу платежей в течении',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Вы уверены, что хотите удалить эту запись? Это действие не может быть отменено.',
  thisActionWillAffect: 'Это действие повлияет на'
};
