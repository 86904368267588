export default {
  acceptance: 'Ready for acceptance',
  account: 'Task account',
  add: 'Add task',
  add_body: 'Add task description',
  addDescription: 'Add task description',
  addTags: 'Add task tags',
  all: 'All tasks',
  alreadyMember: 'Already task member',
  areYouSureYouWantLeaveTaskThisActionCannotBeUndone:
    'Are you sure you want leave the task? This action cannot be undone.',
  description: 'Task description',
  burning: 'Burning tasks',
  caution: 'Caution!',
  cancel: 'Cancel task',
  current: 'Current task',
  changeStatus: 'Change status',
  count: 'Task count',
  create: 'Create task',
  create_multiple: 'Create multiple tasks',
  create_new: 'Create new task',
  details: 'Task Details',
  downloadResults: 'Download results',
  download_excel: 'Download excel document with all tasks',
  download_project_excel: 'Download excel document with all project tasks',
  download_selected_excel: 'Download excel document with selected tasks',
  duplicate: 'Duplicate tasks',
  displayOptions: 'Display options',
  editDescription: 'Edit description',
  edit_description: 'Edit task description',
  edit_details: 'Edit task details',
  enter_name: 'Enter task name',
  favorite: 'Favorite tasks',
  hide: 'Hide task',
  import: 'Import tasks',
  leave: 'Leave task',
  members: 'Task members',
  members_lower_case: 'task members',
  members_genitive_lower_case: 'task members',
  multiple: 'Multiple tasks',
  mute: 'Mute task',
  my: 'My tasks',
  name: 'Task name',
  name_a_z: 'Task name (A-Z)',
  name_z_a: 'Task name (Z-A)',
  new: 'New task',
  'new...': 'What needs to be done?',
  no: 'No tasks',
  pause: 'Pause task',
  pleaseRate: 'Please rate the task',
  plural: 'Tasks',
  pluralLowerCase: 'tasks',
  pluralGenitiveLowerCase: 'tasks',
  plural_prepositional_lower_case: 'tasks',
  profile: 'Task profile',
  finishTasks: 'Finish tasks',
  finishMultipleTasks: 'Finish multiple tasks',
  remove_from: 'Remove from task',
  restore: 'Restore task',
  results: 'Task results',
  findResultsInAttachments: 'Please find the result in attachment',
  submitAsTaskResult: 'Submit as task result',
  submitResults: 'Submit results',
  submitAsSourceFile: 'Submit as source file',
  submitSourceFiles: 'Submit source files',
  submittedResults: 'submitted task result',
  searchByTaskName: 'Search by task name',
  select: 'Select task...',
  selectSingular: 'Select task',
  select_user: 'Select task member',
  selected_users: 'Selected task members',
  set_cost: 'Set task cost',
  sendInvoice: 'Send invoice',
  setDueDate: 'Set task due date',
  show_all: 'Show all tasks',
  show_done: 'Show done tasks',
  show_hidden: 'Show hidden tasks',
  show_visible: 'Show visible tasks',
  show_only_visible: 'Show only visible tasks',
  show_with_canceled: 'Show with canceled tasks',
  show_without_canceled: 'Show without canceled tasks',
  show_with_done: 'Show with done tasks',
  show_without_done: 'Show without done tasks',
  show_with_hidden: 'Show with hidden tasks',
  showHiddenTasks: 'Show hidden tasks',
  showCanceledTasks: 'Show canceled tasks',
  search: 'Search tasks',
  single: 'Single task',
  singular: 'Task',
  singularAttachments: 'Task Attachments',
  toProject: 'To project',
  allAttachments: 'All Task Attachments',
  singular_lower_case: 'task',
  states: {
    acceptance: 'Ready for acceptance',
    canceled: 'Canceled',
    definition: 'Setting task',
    incompleteSpecification: 'Pending more info',
    paused: 'Paused',
    performed: 'Done',
    qa: 'Quality control'
  },
  buttons: {
    accept: 'Accept',
    approve: 'Approve',
    cancel: 'Cancel task',
    checkIn: 'Check in',
    pause: 'Pause task',
    pendingMoreInfo: 'Pending more info',
    restore: 'Restore task',
    sendToRevision: 'Send to revision'
  },
  isClosed: 'Task is closed',
  inviteToMultipleTasks: 'Invite to multiple tasks',
  inviteUser: 'Invite user',
  noPlural: 'No tasks',
  status: 'Task Status',
  status_change: 'Task status change',
  optional: 'Optional',
  tableSettings: 'Table settings',
  tags_change: 'Task tags change',
  taskName: 'Task name',
  taskTeam: 'Task team',
  task_timer: 'Task timer',
  tags: 'Task tags',
  team: 'Task team',
  trackTaskTime: 'Track task time',
  trackTimeInMultipleTasks: 'Track time in multiple tasks',
  trackTime: 'Track time',
  unassigned: 'Unassigned tasks',
  unhide: 'Unhide task',
  view: 'View task',
  optionalWhenYouExpectTheTaskToBeFinished:
    '(optional) When you expect the task to be finished. It can be  changed later.'
};
