import accounts from './accounts';
import categories from './categories';
import evaluations from './evaluations';
import images from './images';
import invoices from './invoices';
import menu from './menu';
import messages from './messages';
import pages from './pages';
import permissions from './permissions';
import products from './products';
import profiles from './profiles';
import projects from './projects';
import projectMemberInvites from './projectMemberInvites';
import results from './results';
import sourceFiles from './sourceFiles';
import statuses from './statuses';
import tags from './tags';
import tasks from './tasks';
import transactionTags from './transactionTags';
import teams from './teams';
import users from './users';
import taskMemberInvites from './taskMemberInvites';

export default {
  accounts,
  categories,
  evaluations,
  images,
  invoices,
  menu,
  messages,
  pages,
  permissions,
  products,
  profiles,
  projects,
  projectMemberInvites,
  results,
  sourceFiles,
  statuses,
  tags,
  taskMemberInvites,
  tasks,
  teams,
  transactionTags,
  users
};
