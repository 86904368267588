export default {
  activateUser: 'Активувати користувача',
  activateWarning: 'Ви впевнені, що хочете активувати обраних користувачів?',
  add: 'Додати користувача',
  addCellsToUsers: 'Додати cells до користувачів',
  addGroupsToUsers: 'Додати групи до користувачів',
  all: 'Всі люди',
  block: 'Заблокувати користувача',
  blockedSingular: 'Заблокований користувач',
  blockedPlural: 'Заблоковані користувачі',
  blockUser: 'Заблокувати користувача',
  blockWarning: 'Ви впевнені, що хочете заблокувати обраних користувачів?',
  create: 'Створити користувача',
  deleteAccount: 'Видалити обліковий запис',
  delete: 'Видалити користувача',
  group: 'Група',
  edit: 'Редагувати обліковий запис',
  invite: 'Запросити користувача',
  plural: 'Користувачі',
  pluralLowerCase: 'користувачі',
  workersPlural: 'Працівники',
  search: 'Пошук користувачів',
  select: 'Обрати користувача...',
  selected: 'Обрані користувачі',
  singular: 'Користувач',
  name: "Ім'я",
  phone: 'Телефон',
  financeRole: 'Фінансова роль',
  experience: 'Досвід',
  revenue: 'Дохід',
  kAvr: 'K AVR',
  kEdp: 'K EDP',
  cell: 'Cell',
  roles: 'Ролі',
  createdAt: 'Дата створення',
  pendingPayments: {
    plural: 'Очікувані платежі',
    singular: 'Очікуваний платіж',
    types: {
      badDebt: 'Безповоротний борг',
      debt: 'Борг',
      underpayment: 'Недоплата'
    },
    no: 'Очікувані платежі відсутні'
  }
};
