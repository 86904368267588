import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

interface WordsType {
  accordingTo: LocalesKey;
  account: LocalesKey;
  activity: LocalesKey;
  add: LocalesKey;
  addMore: LocalesKey;
  addItem: LocalesKey;
  addReaction: LocalesKey;
  addToFavorites: LocalesKey;
  addUser: LocalesKey;
  addYourCommentOptional: LocalesKey;
  adorable: LocalesKey;
  allActivity: LocalesKey;
  allAttachments: LocalesKey;
  allocateFunds: LocalesKey;
  already_selected: LocalesKey;
  amountDue: LocalesKey;
  and: LocalesKey;
  any: LocalesKey;
  anyType: LocalesKey;
  applicationLanguage: LocalesKey;
  artists: LocalesKey;
  assignAsCreator: LocalesKey;
  attach: LocalesKey;
  attachImage: LocalesKey;
  autoAllocate: LocalesKey;
  backToProject: LocalesKey;
  backtrace: LocalesKey;
  badDebt: LocalesKey;
  blocked: LocalesKey;
  brief: LocalesKey;
  browse: LocalesKey;
  budget: LocalesKey;
  cancel: LocalesKey;
  caution: LocalesKey;
  celebrate: LocalesKey;
  clap: LocalesKey;
  clear: LocalesKey;
  clearFilters: LocalesKey;
  clearSelection: LocalesKey;
  clickToCopy: LocalesKey;
  client: LocalesKey;
  clientDebt: LocalesKey;
  clients: LocalesKey;
  close: LocalesKey;
  collapse: LocalesKey;
  colors: LocalesKey;
  confirm: LocalesKey;
  contactSupport: LocalesKey;
  copyId: LocalesKey;
  copyLink: LocalesKey;
  copyUuid: LocalesKey;
  cost: LocalesKey;
  create: LocalesKey;
  created: LocalesKey;
  criticalRequirements: LocalesKey;
  date: LocalesKey;
  dateAndTimeFormat: LocalesKey;
  dateOfBirth: LocalesKey;
  debt: LocalesKey;
  delete: LocalesKey;
  description: LocalesKey;
  descriptionOptional: LocalesKey;
  dislike: LocalesKey;
  download: LocalesKey;
  downloadTaskResult: LocalesKey;
  downloadAllUpperCase: LocalesKey;
  dpi: LocalesKey;
  draftBudget: LocalesKey;
  drawings: LocalesKey;
  dueDate: LocalesKey;
  duplicate: LocalesKey;
  edit: LocalesKey;
  emailAddress: LocalesKey;
  english: LocalesKey;
  errorMessage: LocalesKey;
  eur: LocalesKey;
  expenses: LocalesKey;
  favorite: LocalesKey;
  files: LocalesKey;
  filter: LocalesKey;
  filterFavorite: LocalesKey;
  filters: LocalesKey;
  fire: LocalesKey;
  flagged: LocalesKey;
  forward: LocalesKey;
  from: LocalesKey;
  general: LocalesKey;
  generate: LocalesKey;
  helpCenter: LocalesKey;
  hidden: LocalesKey;
  hide: LocalesKey;
  hideList: LocalesKey;
  images: LocalesKey;
  in: LocalesKey;
  include: LocalesKey;
  info: LocalesKey;
  invisibleForClient: LocalesKey;
  invisibleForWorker: LocalesKey;
  invite_user: LocalesKey;
  joined: LocalesKey;
  languagesSpoken: LocalesKey;
  laughing: LocalesKey;
  leave: LocalesKey;
  leaveMessage: LocalesKey;
  like: LocalesKey;
  link: LocalesKey;
  location: LocalesKey;
  love: LocalesKey;
  mad: LocalesKey;
  media: LocalesKey;
  members: LocalesKey;
  membersGenitiveLowerCase: LocalesKey;
  model: LocalesKey;
  more: LocalesKey;
  myLibrary: LocalesKey;
  name: LocalesKey;
  nameEn: LocalesKey;
  nda: LocalesKey;
  needHelp: LocalesKey;
  new: LocalesKey;
  no: LocalesKey;
  noResultsYet: LocalesKey;
  noSearchResults: LocalesKey;
  ok: LocalesKey;
  openOptions: LocalesKey;
  or: LocalesKey;
  paid: LocalesKey;
  passwordChanged: LocalesKey;
  pay: LocalesKey;
  payer: LocalesKey;
  paymentMethod: LocalesKey;
  payments: LocalesKey;
  pendingPayments: LocalesKey;
  phoneNumber: LocalesKey;
  pinned: LocalesKey;
  policies: LocalesKey;
  postMessage: LocalesKey;
  price: LocalesKey;
  privacy: LocalesKey;
  privacyPolicy: LocalesKey;
  processing: LocalesKey;
  progress: LocalesKey;
  px: LocalesKey;
  receiveNotifications: LocalesKey;
  recently_selected: LocalesKey;
  references: LocalesKey;
  refund: LocalesKey;
  remove: LocalesKey;
  removeFromFavorites: LocalesKey;
  requirements: LocalesKey;
  root: LocalesKey;
  rub: LocalesKey;
  russian: LocalesKey;
  sad: LocalesKey;
  save: LocalesKey;
  saveChanges: LocalesKey;
  saveFilter: LocalesKey;
  savedFilters: LocalesKey;
  scopeOfWork: LocalesKey;
  search: LocalesKey;
  searchResults: LocalesKey;
  search_user_by_name: LocalesKey;
  searching: LocalesKey;
  secondsGenitive: LocalesKey;
  select: LocalesKey;
  selectToAddToTheProject: LocalesKey;
  selected: LocalesKey;
  send: LocalesKey;
  sender: LocalesKey;
  sendInstructions: LocalesKey;
  sendToAllTasks: LocalesKey;
  sendViaEmail: LocalesKey;
  sent: LocalesKey;
  serviceAgreement: LocalesKey;
  setDueDate: LocalesKey;
  settings: LocalesKey;
  show: LocalesKey;
  showAll: LocalesKey;
  showLess: LocalesKey;
  showList: LocalesKey;
  showMore: LocalesKey;
  sketches: LocalesKey;
  sku: LocalesKey;
  startDate: LocalesKey;
  state: LocalesKey;
  status: LocalesKey;
  submit: LocalesKey;
  surnameEn: LocalesKey;
  surprised: LocalesKey;
  system: LocalesKey;
  terrified: LocalesKey;
  termsOfService: LocalesKey;
  textures: LocalesKey;
  threeDStock: LocalesKey;
  threeDs: LocalesKey;
  time: LocalesKey;
  timeZone: LocalesKey;
  to: LocalesKey;
  total: LocalesKey;
  totalDebt: LocalesKey;
  tryAgain: LocalesKey;
  uah: LocalesKey;
  ukraine: LocalesKey;
  underpayment: LocalesKey;
  unselectAllItems: LocalesKey;
  update: LocalesKey;
  updateImageVersions: LocalesKey;
  updated: LocalesKey;
  usd: LocalesKey;
  user: LocalesKey;
  view: LocalesKey;
  viewAll: LocalesKey;
  viewAllMembers: LocalesKey;
  viewCR: LocalesKey;
  viewProfile: LocalesKey;
  visibility: LocalesKey;
  visibilityForClient: LocalesKey;
  visibilityForWorker: LocalesKey;
  visible: LocalesKey;
  visibleForClient: LocalesKey;
  workers: LocalesKey;
  workflowGuide: LocalesKey;
  writeHere: LocalesKey;
  yes: LocalesKey;
  colorSchemeDark: LocalesKey;
  colorSchemeLight: LocalesKey;
  colorScheme: LocalesKey;
  timer: {
    days: LocalesKey;
    hours: LocalesKey;
    minutes: LocalesKey;
    seconds: LocalesKey;
  };
  letters: {
    h: LocalesKey;
    m: LocalesKey;
  };
}

export const words = keyPathMirror<WordsType, string>(
  {
    accordingTo: null,
    account: null,
    activity: null,
    add: null,
    addItem: null,
    addMore: null,
    addReaction: null,
    addToFavorites: null,
    addUser: null,
    addYourCommentOptional: null,
    adorable: null,
    allActivity: null,
    allAttachments: null,
    allocateFunds: null,
    already_selected: null,
    amountDue: null,
    and: null,
    any: null,
    anyType: null,
    applicationLanguage: null,
    artists: null,
    assignAsCreator: null,
    attach: null,
    attachImage: null,
    autoAllocate: null,
    backToProject: null,
    backtrace: null,
    badDebt: null,
    blocked: null,
    brief: null,
    browse: null,
    budget: null,
    cancel: null,
    caution: null,
    celebrate: null,
    clap: null,
    clear: null,
    clearFilters: null,
    clearSelection: null,
    clickToCopy: null,
    client: null,
    clientDebt: null,
    clients: null,
    close: null,
    collapse: null,
    colors: null,
    confirm: null,
    contactSupport: null,
    copyId: null,
    copyLink: null,
    copyUuid: null,
    cost: null,
    create: null,
    created: null,
    criticalRequirements: null,
    date: null,
    dateAndTimeFormat: null,
    dateOfBirth: null,
    debt: null,
    delete: null,
    description: null,
    descriptionOptional: null,
    dislike: null,
    download: null,
    downloadTaskResult: null,
    downloadAllUpperCase: null,
    dpi: null,
    draftBudget: null,
    drawings: null,
    dueDate: null,
    duplicate: null,
    edit: null,
    emailAddress: null,
    english: null,
    errorMessage: null,
    eur: null,
    expenses: null,
    favorite: null,
    files: null,
    filter: null,
    filterFavorite: null,
    filters: null,
    fire: null,
    flagged: null,
    forward: null,
    from: null,
    general: null,
    generate: null,
    helpCenter: null,
    hidden: null,
    hide: null,
    hideList: null,
    images: null,
    in: null,
    include: null,
    info: null,
    invisibleForClient: null,
    invisibleForWorker: null,
    invite_user: null,
    joined: null,
    languagesSpoken: null,
    laughing: null,
    leave: null,
    leaveMessage: null,
    like: null,
    link: null,
    location: null,
    love: null,
    mad: null,
    media: null,
    members: null,
    membersGenitiveLowerCase: null,
    model: null,
    more: null,
    myLibrary: null,
    name: null,
    nameEn: null,
    nda: null,
    needHelp: null,
    new: null,
    no: null,
    noResultsYet: null,
    noSearchResults: null,
    ok: null,
    openOptions: null,
    or: null,
    paid: null,
    passwordChanged: null,
    pay: null,
    payer: null,
    paymentMethod: null,
    payments: null,
    pendingPayments: null,
    phoneNumber: null,
    pinned: null,
    policies: null,
    postMessage: null,
    price: null,
    privacy: null,
    privacyPolicy: null,
    processing: null,
    progress: null,
    px: null,
    receiveNotifications: null,
    recently_selected: null,
    references: null,
    refund: null,
    remove: null,
    removeFromFavorites: null,
    requirements: null,
    root: null,
    rub: null,
    russian: null,
    sad: null,
    save: null,
    saveChanges: null,
    saveFilter: null,
    savedFilters: null,
    scopeOfWork: null,
    search: null,
    searchResults: null,
    search_user_by_name: null,
    searching: null,
    secondsGenitive: null,
    select: null,
    selectToAddToTheProject: null,
    selected: null,
    send: null,
    sender: null,
    sendInstructions: null,
    sendToAllTasks: null,
    sendViaEmail: null,
    sent: null,
    serviceAgreement: null,
    setDueDate: null,
    settings: null,
    show: null,
    showAll: null,
    showLess: null,
    showList: null,
    showMore: null,
    sketches: null,
    sku: null,
    startDate: null,
    state: null,
    status: null,
    submit: null,
    surnameEn: null,
    surprised: null,
    system: null,
    terrified: null,
    termsOfService: null,
    textures: null,
    threeDStock: null,
    threeDs: null,
    time: null,
    timeZone: null,
    to: null,
    total: null,
    totalDebt: null,
    tryAgain: null,
    uah: null,
    ukraine: null,
    underpayment: null,
    unselectAllItems: null,
    update: null,
    updateImageVersions: null,
    updated: null,
    usd: null,
    user: null,
    view: null,
    viewAll: null,
    viewAllMembers: null,
    viewCR: null,
    viewProfile: null,
    visibility: null,
    visibilityForClient: null,
    visibilityForWorker: null,
    visible: null,
    visibleForClient: null,
    workers: null,
    workflowGuide: null,
    writeHere: null,
    yes: null,
    timer: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    },
    colorScheme: null,
    colorSchemeDark: null,
    colorSchemeLight: null,
    letters: {
      h: null,
      m: null
    }
  },
  'words.'
);
