import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SourceFilesKeyType {
  downloadPlural: LocalesKey;
  plural: LocalesKey;
  uploadPlural: LocalesKey;
}

export const sourceFilesKeys = keyPathMirror<SourceFilesKeyType, string>(
  {
    downloadPlural: null,
    plural: null,
    uploadPlural: null
  },
  'models.sourceFiles.'
);
